// src/app/screens/UserDashboard.js
import React, { useState, useEffect } from 'react';
import './UserDashboard.css';
import appleAppStore from '../../assets/logos/app_store.png';
import googlePlay from '../../assets/logos/google_play.png';

const UserDashboard = () => {
  const [userStats, setUserStats] = useState({
    totalChats: 0,
    totalWorkoutPlans: 0,
    lastLogin: '',
  });

  useEffect(() => {
    // Fetch user stats from API
    // This is a placeholder, replace with actual API call
    setUserStats({
      totalChats: 15,
      totalWorkoutPlans: 3,
      lastLogin: '2023-05-01',
    });
  }, []);

  return (
    <div className="dashboard-content">

      <div className="app-info">
        <h2>Download the Vitalise.ai Mobile App</h2>
        <p>
          Access all Vitalise.ai features on our mobile app. Continue previous chats, 
          generate health plans, enjoy social features, track health with wearables, and more.
        </p>
        <div className="app-download-links">
          <img src={appleAppStore} alt="Download on the Apple App Store" className="app-store-logo" />
          <img src={googlePlay} alt="Get it on Google Play" className="app-store-logo" />
        </div>
      </div>
      
      <div className="user-stats">
        <h2>Your Stats</h2>
        <p>Total Chats: {userStats.totalChats}</p>
        <p>Workout Plans: {userStats.totalWorkoutPlans}</p>
        <p>Last Login: {userStats.lastLogin}</p>
      </div>

      <div className="assistants-preview">
        <h2>Your Assistants</h2>
        {/* Display up to 3 assistant previews here */}
      </div>

      <button className="test-assistant-btn">Test Assistant</button>
    </div>
  );
};

export default UserDashboard;