// src/docs/helpers/preloadContent.js
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Introduction from '../pages/Introduction';
import GettingStarted from '../pages/GettingStarted';
import Settings from '../pages/Settings';

const extractSectionsFromHTML = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  const sections = Array.from(tempDiv.querySelectorAll('h1, h2, h3')).map(section => {
    const nextSibling = section.nextElementSibling;
    const content = nextSibling && nextSibling.tagName === 'P' ? nextSibling.innerText : '';
    return {
      id: section.id || '',
      text: section.innerText || '',
      content,
    };
  });
  return sections;
};

export const preloadPages = () => {
  const pages = [
    { path: '/docs/introduction', content: <Introduction /> },
    { path: '/docs/getting-started', content: <GettingStarted /> },
    { path: '/docs/settings', content: <Settings /> },
    // Add other pages here
  ];

  return pages.map(page => {
    const pageHTML = ReactDOMServer.renderToString(page.content);
    const sections = extractSectionsFromHTML(pageHTML);
    return { path: page.path, sections };
  });
};