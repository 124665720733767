// src/screens/Pricing.js
import React, { useState } from 'react';
import './Pricing.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Pricing = () => {
    const [billingPeriod, setBillingPeriod] = useState('yearly');

    const handleBillingPeriodChange = (period) => {
        setBillingPeriod(period);
    };

    return (
        <div className="pricing-page">
            <h1 className="pricing-header">Pricing</h1>
            <div className="pricing-toggle">
                <div className="toggle-wrapper">
                    <button
                        className={`yearly ${billingPeriod === 'yearly' ? 'active' : ''}`}
                        onClick={() => handleBillingPeriodChange('yearly')}
                    >
                        <span>Yearly</span> <span className="save">Save 35%</span>
                        {billingPeriod === 'yearly' && <div className="toggle-effect" />}
                    </button>
                    <button
                        className={`monthly ${billingPeriod === 'monthly' ? 'active' : ''}`}
                        onClick={() => handleBillingPeriodChange('monthly')}
                    >
                        <span>Monthly</span>
                        {billingPeriod === 'monthly' && <div className="toggle-effect" />}
                    </button>
                </div>
            </div>
            <div className="pricing-tiers">
                <div className="tier free">
                    <div className="content">
                        <h2>Free</h2>
                        <p className="price"><span className="main-price">Free</span> <span className="sub-price">forever</span></p>
                        <ul>
                            <li><FaCheckCircle /> 50 Chat Tokens (CTs), renewed monthly</li>
                            <li><FaCheckCircle /> 1 custom AI assistant</li>
                        </ul>
                    </div>
                    <Link to="/signup"><button>Try it now</button></Link>
                </div>
                <div className="tier standard">
                    <div className="most-popular">Most Popular</div>
                    <div className="content">
                        <h2>Standard</h2>
                        <p className="price">
                            <span className="main-price">{billingPeriod === 'yearly' ? '$7' : '$10'}</span>
                            <span className="sub-price">{billingPeriod === 'yearly' ? '/ per month billed yearly' : '/ per month'}</span>
                        </p>
                        <ul>
                            <li><FaCheckCircle /> 100 Chat Tokens (CTs), renewed monthly</li>
                            <li><FaCheckCircle /> 3 custom AI assistants</li>
                            <li><FaCheckCircle /> Advanced in-app settings</li>
                            <li><FaCheckCircle /> Access to custom functions</li>
                            <li><FaCheckCircle /> Access to Social Functions</li>
                        </ul>
                    </div>
                    <button>Coming Soon</button>
                </div>
                <div className="tier premium">
                    <div className="content">
                        <h2>Premium</h2>
                        <p className="price">
                            <span className="main-price">{billingPeriod === 'yearly' ? '$15' : '$20'}</span>
                            <span className="sub-price">{billingPeriod === 'yearly' ? '/ per month billed yearly' : '/ per month'}</span>
                        </p>
                        <ul>
                            <li><FaCheckCircle /> Same as Standard</li>
                            <li><FaCheckCircle /> Unlimited Chat Tokens (CTs)</li>
                            <li><FaCheckCircle /> Unlimited AI assistants</li>
                            <li><FaCheckCircle /> Wearables integration</li>
                        </ul>
                    </div>
                    <button>Coming Soon</button>
                </div>
            </div>
            
            <div className="pricing-faq-section">
                <h2>FAQ</h2>
                <div className="pricing-faq-item">
                    <h3>What is a Chat Token (CT)?</h3>
                    <p>A Chat Token (CT) is a unit of interaction with the AI assistant. Each message or query consumes a certain number of CTs.</p>
                </div>
                <div className="pricing-faq-item">
                    <h3>What can custom AI assistants do?</h3>
                    <p>Custom AI assistants can be tailored to your specific needs, providing personalized advice and seamlessly generating workout plans, nutrition plans, routines, and more. All these services are done in natural language, customized to your profile, and based on your custom AI assistant.</p>
                </div>
                <div className="pricing-faq-item">
                    <h3>How does the integration with wearables work?</h3>
                    <p>The integration with wearables allows the AI assistant to use data from your devices to provide more accurate and context-aware advice. This enables advanced data analysis from wearables such as Apple, Fitbit, Garmin, Oura, Samsung, and Whoop, using this information as context for all conversations. Currently, Apple integration is available, with the others coming soon.</p>
                </div>
            </div>
        </div>
    );
}

export default Pricing;
