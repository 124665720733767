import React, { useState, useEffect } from 'react';
import './UserAssistants.css';

const UserAssistants = () => {
  const [assistants, setAssistants] = useState([]);

  useEffect(() => {
    // Fetch user's assistants from API
    // This is a placeholder, replace with actual API call
  }, []);

  return (
    <div className="assistants-content">
      <h1>Your Assistants</h1>
      {assistants.map((assistant, index) => (
        <div key={index} className="assistant-card">
          <h2>{assistant.name}</h2>
          <p>{assistant.description}</p>
          <button>Edit</button>
          <button>Delete</button>
        </div>
      ))}
      <button className="create-assistant-btn">Create New Assistant</button>
    </div>
  );
};

export default UserAssistants;