// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/docs/DocsFooter.css */
.docs-footer {
  height: 50px;
  text-align: center;
  padding: 10px 0;
  background-color: var(--background-color);
  color: var(--text-color);
  border-top: 1px solid #333;
  z-index: 1000;
}
  `, "",{"version":3,"sources":["webpack://./src/docs/DocsFooter.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yCAAyC;EACzC,wBAAwB;EACxB,0BAA0B;EAC1B,aAAa;AACf","sourcesContent":["/* src/docs/DocsFooter.css */\n.docs-footer {\n  height: 50px;\n  text-align: center;\n  padding: 10px 0;\n  background-color: var(--background-color);\n  color: var(--text-color);\n  border-top: 1px solid #333;\n  z-index: 1000;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
