// src/docs/DocsFooter.js
import React from 'react';
import './DocsFooter.css';

const DocsFooter = () => {
  return (
    <footer className="docs-footer">
      <p>2024 © Vitalise.ai Documentation</p>
    </footer>
  );
};

export default DocsFooter;
