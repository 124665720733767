// src/docs/pages/Settings.js
import React, { useState } from 'react';
import './Pages.css';
import ImageModal from '../helpers/ImageModal';

import GeneralSettings from '../../assets/docs/settings1.jpg';
import AudioSettings from '../../assets/docs/settings2.jpg';

const Settings = () => {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (src, alt) => {
    setModalImage({ src, alt });
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div>
      <h1 id="Introduction Settings">Settings</h1>
      <p>Welcome to the Settings guide for VitaliseAI. Here you can learn how to customize your app experience to suit your preferences. From changing themes to adjusting notification settings, this guide covers all the essential settings you need to know.</p>
      
      <h2 id="Customization-Options">Customization Options</h2>
      <p>VitaliseAI offers a variety of settings to personalize your experience. You can adjust sound settings, change themes, and configure notifications to ensure the app works best for you.</p>

      <div className="settings-row">
        <div className="settings-text">
          <h3 id="Sound-Settings">1. Sound Settings</h3>
          <p>Control the app's sound settings to match your preferences. You can enable or disable sound notifications and adjust the volume for different types of alerts.</p>
        </div>
        <img 
          src={AudioSettings} 
          alt="Audio Settings" 
          className="page-image" 
          onClick={() => openModal(AudioSettings, "Audio Settings")}
        />
      </div>

      <div className="settings-row">
        <div className="settings-text">
          <h3 id="General-Settings">2. General Settings</h3>
          <p>Customize the language and theme of the app to suit your needs. Choose between different themes like Default, Dark, and Light to enhance your user experience.</p>
        </div>
        <img 
          src={GeneralSettings} 
          alt="General Settings" 
          className="page-image" 
          onClick={() => openModal(GeneralSettings, "General Settings")}
        />
      </div>

      <h3 id="Notification-Settings">3. Notification Settings</h3>
      <p>Stay updated with the latest health insights and reminders by configuring your notification preferences. You can choose which notifications to receive and how often you want to be alerted.</p>

      <h3 id="Privacy-Settings">4. Privacy Settings</h3>
      <p>Manage your privacy settings to control what data is shared and how it is used. VitaliseAI prioritizes your privacy and provides options to ensure your data is handled securely.</p>
      
      <p className="update-date">Last updated on July 9, 2024</p>

      {modalImage && (
        <ImageModal 
          src={modalImage.src} 
          alt={modalImage.alt} 
          onClose={closeModal} 
        />
      )}
    </div>
  );
};

export default Settings;