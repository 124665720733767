import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './News.css';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import LoadingAnim from '../components/LoadingAnim';

import axios from 'axios';

const News = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
    
    const [allNewsItems, setAllNewsItems] = useState([]);
    const [newsItems, setNewsItems] = useState([]);
    const [pinnedItems, setPinnedItems] = useState([]);
    const [oldItems, setOldItems] = useState([]);
    const [showOldPosts, setShowOldPosts] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { articleId } = useParams();
    const location = useLocation();

    useEffect(() => {
        fetchNewsItems();
    }, []);

    useEffect(() => {
        if (location.pathname === '/news') {
            setSelectedArticle(null);
        } else if (articleId && allNewsItems.length > 0) {
            const article = allNewsItems.find(item => `${item.title.replace(/\s+/g, '_')}_${item.id}` === articleId);
            if (article) {
                setSelectedArticle(article);
                if (article.type === 'old' || article.type === 'old_link') {
                    setShowOldPosts(true);
                }
            }
        }
    }, [location, articleId, allNewsItems]);

    const handleBackToNews = () => {
        navigate('/news', { replace: true });
        setSelectedArticle(null);
    };

    const fetchNewsItems = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_BASE_URL}/get-news-items`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = response.data.map(item => ({
                    ...item,
                    image_url: item.has_image ? item.image_url : null
                }));
                setAllNewsItems(data);
                setNewsItems(data.filter(item => item.type === 'news' || item.type === 'news_link').sort((a, b) => a.display_rank - b.display_rank));
                setPinnedItems(data.filter(item => item.type === 'pinned' || item.type === 'pinned_link').sort((a, b) => a.display_rank - b.display_rank));
                setOldItems(data.filter(item => item.type === 'old' || item.type === 'old_link').sort((a, b) => a.display_rank - b.display_rank));
            } else {
                console.error("Failed to fetch news items:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Error fetching news items:", error);
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleArticleClick = (article) => {
        if (article.type.endsWith('_link')) {
            window.open(article.short_description, '_blank');
        } else {
            const articleId = `${article.title.replace(/\s+/g, '_')}_${article.id}`;
            navigate(`/news/${articleId}`);
            setSelectedArticle(article);
        }
    };

    const renderArticles = (articles) => {
        return articles.map((article, index) => (
            <div key={index} className={`article-card ${article.has_image ? 'with-image' : 'no-image'}`} onClick={() => handleArticleClick(article)}>
                {article.has_image && (
                    <div className="article-image-container">
                        <img 
                            src={article.image_url} 
                            alt={article.title} 
                            className="article-image"
                            onLoad={(e) => setImageAspectRatio(e.target)}
                        />
                    </div>
                )}
                <div className="article-content">
                    <h2 className="article-title">{article.title}</h2>
                    <p className="article-date">{new Date(article.date_created).toLocaleDateString()}</p>
                    {!article.type.endsWith('_link') && <p className="article-description">{article.short_description}</p>}
                </div>
            </div>
        ));
    };

    const setImageAspectRatio = (img) => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        img.parentElement.style.setProperty('--aspect-ratio', aspectRatio);
    };

    const renderPinnedArticles = () => {
        return pinnedItems.map((article, index) => (
            <div key={index} className="pinned-article" onClick={() => handleArticleClick(article)}>
                <h3 className="pinned-title">{article.title}</h3>
                <p className="pinned-date">{new Date(article.date_created).toLocaleDateString()}</p>
            </div>
        ));
    };

    const renderSelectedArticle = () => {
        if (!selectedArticle) return null;
        const contentHtml = selectedArticle.content ? stateToHTML(convertFromRaw(JSON.parse(selectedArticle.content))) : '';
        
        const handlePreviousPost = () => {
            if (selectedArticle.link_to_previous) {
                if (selectedArticle.link_to_previous.startsWith('http')) {
                    window.location.href = selectedArticle.link_to_previous;
                } else {
                    navigate(selectedArticle.link_to_previous);
                }
            }
        };

        return (
            <div className="selected-article">
                <h1>{selectedArticle.title}</h1>
                {selectedArticle.has_image && <img src={selectedArticle.image_url} alt={selectedArticle.title} />}
                <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
                {selectedArticle.link_to_previous && (
                    <button className="previous-post-button" onClick={handlePreviousPost}>
                        See previous post in this series
                    </button>
                )}
                <p className="last-updated">Last updated: {new Date(selectedArticle.last_updated || selectedArticle.date_created).toLocaleString()}</p>
                <button className="back-to-news-button" onClick={handleBackToNews}>Back to News</button>
            </div>
        );
    };

    const toggleOldPosts = () => {
        setShowOldPosts(!showOldPosts);
        setCurrentPage(1);
    };

    const paginatedOldItems = oldItems.slice((currentPage - 1) * 10, currentPage * 10);

    return (
        <div className="news-page">
            <h1 className="news-header">News</h1>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingAnim />
                </div>
            ) : selectedArticle ? (
                renderSelectedArticle()
            ) : (
                <>
                    <div className="news-content">
                        <div className="main-news">
                            {showOldPosts ? renderArticles(paginatedOldItems) : renderArticles(newsItems)}
                        </div>
                        <div className="pinned-articles">
                            {renderPinnedArticles()}
                        </div>
                    </div>
                    <div className="news-controls">
                        {oldItems.length > 0 && (
                            <button className="toggle-posts-button" onClick={toggleOldPosts}>
                                {showOldPosts ? "See newest posts" : "See older posts"}
                            </button>
                        )}
                        {showOldPosts && (
                            <div className="pagination-container">
                                <div className="pagination">
                                    <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</button>
                                    <span>Page {currentPage}</span>
                                    <button onClick={() => setCurrentPage(prev => prev + 1)} disabled={currentPage * 10 >= oldItems.length}>Next</button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default News;