import React, { useState, useEffect } from 'react';
import './Account.css';

const Account = () => {
  const [accountInfo, setAccountInfo] = useState({
    email: '',
    subscriptionPlan: '',
    billingHistory: [],
  });

  useEffect(() => {
    // Fetch account information from API
    // This is a placeholder, replace with actual API call
  }, []);

  return (
    <div className="account-content">
      <h1>Account Settings</h1>
      <div className="account-info">
        <h2>Email: {accountInfo.email}</h2>
        <h2>Subscription Plan: {accountInfo.subscriptionPlan}</h2>
        <button>Change Password</button>
        <button>Manage Subscription</button>
      </div>
      <div className="billing-history">
        <h2>Billing History</h2>
        {accountInfo.billingHistory.map((bill, index) => (
          <div key={index} className="bill-item">
            <p>Date: {bill.date}</p>
            <p>Amount: {bill.amount}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Account;