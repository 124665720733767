// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-sidebar {
  width: 200px;
  background-color: var(--background-color);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding-top: 20px;
  border-right: 1px solid var(--border-color);
  flex-shrink: 0;
}

.user-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-sidebar li {
  margin-bottom: 10px;
}

.user-sidebar a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: var(--text-color);
  text-decoration: none;
  font-size: 16px;
  font-weight: 550;
  transition: background-color 0.3s ease;
  border-radius: 25px;
}

.user-sidebar li.active a,
.user-sidebar a:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.user-sidebar svg {
  margin-right: 10px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/UserSidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yCAAyC;EACzC,mCAA2B;UAA3B,2BAA2B;EAC3B,iBAAiB;EACjB,2CAA2C;EAC3C,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;;EAEE,wCAAwC;EACxC,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".user-sidebar {\n  width: 200px;\n  background-color: var(--background-color);\n  backdrop-filter: blur(10px);\n  padding-top: 20px;\n  border-right: 1px solid var(--border-color);\n  flex-shrink: 0;\n}\n\n.user-sidebar ul {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n\n.user-sidebar li {\n  margin-bottom: 10px;\n}\n\n.user-sidebar a {\n  display: flex;\n  align-items: center;\n  padding: 10px 20px;\n  color: var(--text-color);\n  text-decoration: none;\n  font-size: 16px;\n  font-weight: 550;\n  transition: background-color 0.3s ease;\n  border-radius: 25px;\n}\n\n.user-sidebar li.active a,\n.user-sidebar a:hover {\n  background-color: var(--secondary-color);\n  color: var(--primary-color);\n}\n\n.user-sidebar svg {\n  margin-right: 10px;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
