// src/components/Home.js
import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import './HomeImages.css';
import { useNavigate } from 'react-router-dom';
import { FaUserMd, FaRegComments, FaHeartbeat, FaDumbbell, FaPodcast, FaBrain, FaInfoCircle, FaPuzzlePiece, FaLightbulb, FaArrowDown, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';

import expertImage1 from '../assets/images/huberman.jpg';
import expertImage2 from '../assets/images/attia.jpg';
import expertImage3 from '../assets/images/rogan.jpg';
import expertImage4 from '../assets/images/4.jpg';
import expertImage5 from '../assets/images/berry.png'; // change to jpg
import expertImage6 from '../assets/images/chaffee.jpg';
import expertImage7 from '../assets/images/7.jpg';
import expertImage8 from '../assets/images/8.jpg';
import expertImage9 from '../assets/images/9.jpg';
import expertImage10 from '../assets/images/10.jpg';
import expertImage11 from '../assets/images/11.jpg';
import expertImage12 from '../assets/images/12.jpg';
import expertImage13 from '../assets/images/13.jpg';
import expertImage14 from '../assets/images/14.jpg';
import expertImage15 from '../assets/images/15.jpg';
import expertImage16 from '../assets/images/16.jpg';

import appMockupImage from '../assets/mockups/screens.png';

import appleAppStore from '../assets/logos/app_store.png';
import googlePlay from '../assets/logos/google_play.png';

import video from '../assets/videos/vitalise.mp4';

const Home = () => {
    const navigate = useNavigate();

    const solutionRef = useRef();
    const heroRef = useRef();
    const downArrowRef = useRef();
    const lastIconRef = useRef();
    const [problemVisible, setProblemVisible] = useState(false);
    const videoPath = video;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Apply animation to hero section elements when the component mounts
        const heroElements = heroRef.current.querySelectorAll('h1, p, .hero-button');
        heroElements.forEach(element => {
            element.classList.add('fade-in-slide-up');
        });

        // Animate the down-arrow after a short delay
        const downArrowTimeout = setTimeout(() => {
            if (downArrowRef.current) {
                downArrowRef.current.classList.add('fade-in-slide-up');
            }
        }, 500); // delay
        
        const problemObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setProblemVisible(true);
                    // Trigger animation for problem section elements
                    const problemElements = document.querySelectorAll('.problem-section h2, .problem-section .icon-text');
                    problemElements.forEach(element => {
                        element.classList.add('fade-in-slide-up');
                    });
                }
            });
        }, { threshold: 0.5 });
    
        const currentLastIcon = lastIconRef.current; // Copy lastIconRef.current to a variable
        if (currentLastIcon) {
            problemObserver.observe(currentLastIcon);
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-slide-up');
                }
            });
        }, { threshold: 0.5 });
    
        let elementsToAnimate;
        if (!isMobile) {
            elementsToAnimate = document.querySelectorAll(`
                .solution-text .icon-text, .solution-section h2, .solution-image, .hero-button,
                .howitworks-section h2, .howitworks-section video,
                .platform-section h2, .platform-logos img,
                .quotes-section blockquote,
                .features-section h2, .feature,
                .testimonials-section h2, .testimonial,
                .additional-content-section h2, .additional-content-item,
                .cta-section h2, .cta-section .social-icons, .cta-section .cta-buttons,
                .expert-image
            `);
        } else {
            elementsToAnimate = document.querySelectorAll(`
                .problem-section h2, .scenario-text, .icon-text, .icon, .icon-text,
                .solution-text .icon-text, .solution-section h2, .solution-image, .hero-button,
                .howitworks-section h2, .howitworks-section video,
                .platform-section h2, .platform-logos img,
                .quotes-section blockquote,
                .features-section h2, .feature,
                .testimonials-section h2, .testimonial,
                .additional-content-section h2, .additional-content-item,
                .cta-section h2, .cta-section .social-icons, .cta-section .cta-buttons,
                .expert-image
            `);
        }
        
        elementsToAnimate.forEach(element => {
            observer.observe(element);
        });
    
        return () => {
            if (!isMobile) {
                if (currentLastIcon) {
                    problemObserver.unobserve(currentLastIcon);
                }
            }
            elementsToAnimate.forEach(element => {
                observer.unobserve(element);
            });
            clearTimeout(downArrowTimeout);
        };
    }, [isMobile]);

    useEffect(() => {
        if (isMobile) {
            const problemObserver = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in-slide-up');
                    }
                });
            }, { threshold: 0.1 });

            const problemElements = document.querySelectorAll('.problem-section .icon-text');
            problemElements.forEach(element => {
                problemObserver.observe(element);
            });

            return () => {
                problemElements.forEach(element => {
                    problemObserver.unobserve(element);
                });
            };
        }
    }, [isMobile]);

    return (
        <div className="home">
            <div className="hero-section" ref={heroRef}>
                <h1>AI-Driven Health Insights</h1>
                <h1>Anytime, Anywhere</h1>
                <p>
                    Vitalise.ai leverages advanced artificial intelligence and natural language processing to empower individuals with personalized health management. Experience a dynamic shift from passive health information consumption to active, science-based health management.
                </p>
                <button className="hero-button">Get started for free</button>
                <div className="down-arrow" ref={downArrowRef}>
                    <FaArrowDown />
                </div>
            </div>

            <div className={`problem-section ${problemVisible || isMobile ? 'visible' : 'hidden'}`}>
                <h2>Can you see yourself in the following scenario?</h2>
                <div className="scenario-text">
                    <p>At Vitalise.ai, we understand the unique challenges faced by health-conscious consumers. Imagine diligently following podcasts from multiple health experts. How do you keep track of all their advice?</p>
                </div>
                <div className="scenario-content">
                    <div className="icons-content">
                        <div className="icon-text">
                            <FaPodcast className="icon" />
                            <p>Hundreds of podcast episodes from different experts.</p>
                        </div>
                        <div className="icon-text">
                            <FaBrain className="icon" />
                            <p>Remembering all the details is challenging.</p>
                        </div>
                        <div className="icon-text">
                            <AiOutlineFundProjectionScreen className="icon" />
                            <p>Each expert offers unique perspectives.</p>
                        </div>
                        <div className="icon-text">
                            <FaInfoCircle className="icon" />
                            <p>Information overload makes it hard to synthesize advice.</p>
                        </div>
                        <div className="icon-text" ref={lastIconRef}>
                            <FaPuzzlePiece className="icon" />
                            <p>Applying available information to your individual needs can be difficult.</p>
                        </div>
                    </div>
                    <div className="expert-images">
                        <img src={expertImage1} alt="Expert 1" title="Andrew Huberman" className="expert-image expert-image1" onClick={() => window.open(`/experts?selected=1`, '_blank')} />
                        <img src={expertImage2} alt="Expert 2" title="Peter Attia" className="expert-image expert-image2" onClick={() => window.open(`/experts?selected=2`, '_blank')} />
                        <img src={expertImage3} alt="Expert 3" title="Joe Rogan" className="expert-image expert-image3" onClick={() => window.open(`/experts?selected=3`, '_blank')} />
                        <img src={expertImage4} alt="Expert 4" title="Expert 4 Name" className="expert-image expert-image4" onClick={() => window.open(`/experts?selected=4`, '_blank')} />
                        <img src={expertImage5} alt="Expert 5" title="Expert 5 Name" className="expert-image expert-image5" onClick={() => window.open(`/experts?selected=5`, '_blank')} />
                        <img src={expertImage6} alt="Expert 6" title="Expert 6 Name" className="expert-image expert-image6" onClick={() => window.open(`/experts?selected=6`, '_blank')} />
                        <img src={expertImage7} alt="Expert 7" title="Expert 7 Name" className="expert-image expert-image7" onClick={() => window.open(`/experts?selected=7`, '_blank')} />
                        <img src={expertImage8} alt="Expert 8" title="Expert 8 Name" className="expert-image expert-image8" onClick={() => window.open(`/experts?selected=8`, '_blank')} />
                        <img src={expertImage9} alt="Expert 9" title="Expert 9 Name" className="expert-image expert-image9" onClick={() => window.open(`/experts?selected=9`, '_blank')} />
                        <img src={expertImage10} alt="Expert 10" title="Expert 10 Name" className="expert-image expert-image10" onClick={() => window.open(`/experts?selected=10`, '_blank')} />
                        <img src={expertImage11} alt="Expert 11" title="Expert 11 Name" className="expert-image expert-image11" onClick={() => window.open(`/experts?selected=11`, '_blank')} />
                        <img src={expertImage12} alt="Expert 12" title="Expert 12 Name" className="expert-image expert-image12" onClick={() => window.open(`/experts?selected=12`, '_blank')} />
                        <img src={expertImage13} alt="Expert 13" title="Expert 13 Name" className="expert-image expert-image13" onClick={() => window.open(`/experts?selected=13`, '_blank')} />
                        <img src={expertImage14} alt="Expert 14" title="Expert 14 Name" className="expert-image expert-image14" onClick={() => window.open(`/experts?selected=14`, '_blank')} />
                        <img src={expertImage15} alt="Expert 15" title="Expert 15 Name" className="expert-image expert-image15" onClick={() => window.open(`/experts?selected=15`, '_blank')} />
                        <img src={expertImage16} alt="Expert 16" title="Expert 16 Name" className="expert-image expert-image16" onClick={() => window.open(`/experts?selected=16`, '_blank')} />
                    </div>
                </div>
            </div>

            <div className="solution-section" ref={solutionRef}>
                <h2>Our Solution</h2>
                <div className={`solution-content ${isMobile ? 'mobile' : ''}`}>
                    {!isMobile && <img src={appMockupImage} alt="Health Expert" className="solution-image" />}
                    <div className="solution-text">
                        <div className="icon-text">
                            <FaLightbulb className="icon" />
                            <p>
                            Vitalise.ai uses AI to combine expert advice with your data for accurate, relevant results in seconds.
                            </p>
                        </div>
                        <div className="icon-text">
                            <FaBrain className="icon" />
                            <p>
                                Seamless integration with wearables for real-time personalized health insights.
                            </p>
                        </div>
                        <div className="icon-text">
                            <FaUserMd className="icon" />
                            <p>
                                Personalized digital health assistant available 24/7 with expert-driven advice.
                            </p>
                        </div>
                        <div className="icon-text">
                            <FaRegComments className="icon" />
                            <p>
                                Natural language processing for intuitive dialogue and intent recognition.
                            </p>
                        </div>
                        <div className="icon-text">
                            <FaHeartbeat className="icon" />
                            <p>
                                Dynamic health plan generation and interactive workout assistance.
                            </p>
                        </div>
                    </div>
                    {isMobile && <img src={appMockupImage} alt="Health Expert" className="solution-image" />}
                </div>
                <div className="button-container">
                    <button className="hero-button">Learn More</button>
                </div>
            </div>

            <div className="howitworks-section">
                <h2>How It Works</h2>
                <video 
                    src={videoPath}
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    controlsList="nodownload nofullscreen noremoteplayback"
                ></video>
            </div>

            <div className="platform-section">
                <h2>Supported Platforms</h2>
                <div className="platform-logos">
                    <img src={appleAppStore} alt="Apple App Store" />
                    <img src={googlePlay} alt="Google Play Store" />
                </div>
            </div>

            <div className="quotes-section">
                <blockquote>
                    Fun fact: The average person only remembers 20% of what they hear in a podcast. Vitalise.ai ensures you get the most relevant info tailored to you. 📚
                </blockquote>
                <blockquote>
                    Vitalise.ai integrates seamlessly with your lifestyle, offering personalized health advice 24/7. 🕒
                </blockquote>
                <blockquote>
                    Vitalise.ai uses tens of millions of text characters from expert sources for every single query you make, tailored just for you. 📖🤯
                </blockquote>
            </div>

            <div className="features-section">
                <h2>Core Features</h2>
                <div className="features-grid">
                    <div className="feature">
                        <FaUserMd className="feature-icon" />
                        <h3>Personalized Digital Health Assistant</h3>
                        <p>
                            Vitalise.ai integrates your personal profile and data from wearables to deliver highly accurate, relevant results tailored specifically to you. Customize your assistant with trusted health experts and research materials.
                        </p>
                    </div>
                    <div className="feature">
                        <FaRegComments className="feature-icon" />
                        <h3>Seamless User Interaction</h3>
                        <p>
                            Interact with your digital health assistant naturally, using text or voice input. The AI interprets your intent to provide information, insights, or health management functions.
                        </p>
                    </div>
                    <div className="feature">
                        <FaHeartbeat className="feature-icon" />
                        <h3>Dynamic Health Plan Generation</h3>
                        <p>
                            Generate personalized, structured health plans within the app. Start, modify, and engage with these plans, receiving real-time guidance to enhance effectiveness.
                        </p>
                    </div>
                    <div className="feature">
                        <FaDumbbell className="feature-icon" />
                        <h3>Interactive Workout Assistance</h3>
                        <p>
                            Receive detailed instructions and real-time guidance for workouts and health plans, ensuring safe and effective performance of each activity.
                        </p>
                    </div>
                </div>
            </div>

            <div className="testimonials-section">
                <h2>What Our Users Say</h2>
                <div className="testimonial">
                    <p>"Vitalise.ai has completely transformed how I manage my health. The personalized plans are a game changer!"</p>
                    <cite>— Alex, Health Enthusiast</cite>
                </div>
                <div className="testimonial">
                    <p>"I love how the AI understands my needs and provides actionable advice. It's like having a personal health coach available 24/7."</p>
                    <cite>— Jamie, Fitness Guru</cite>
                </div>
            </div>

            <div className="additional-content-section">
                <h2>Why Choose Vitalise.ai?</h2>
                <div className="additional-content-grid">
                    <div className="additional-content-item">
                        <h3>AI-Powered Personalization</h3>
                        <p>
                            Our advanced AI algorithms analyze your unique health data and preferences to provide personalized advice and plans that adapt as you do.
                        </p>
                    </div>
                    <div className="additional-content-item">
                        <h3>Expert-Backed Insights</h3>
                        <p>
                            Vitalise.ai synthesizes information from leading health experts and research materials to ensure you receive accurate and science-based recommendations.
                        </p>
                    </div>
                    <div className="additional-content-item">
                        <h3>Comprehensive Health Management</h3>
                        <p>
                            From diet and exercise to mental wellness, our platform covers all aspects of health, providing you with comprehensive tools and resources.
                        </p>
                    </div>
                    <div className="additional-content-item">
                        <h3>Real-Time Updates</h3>
                        <p>
                            Stay informed with real-time updates and notifications, helping you to stay on track and achieve your health goals effectively.
                        </p>
                    </div>
                </div>
            </div>

            <div className="cta-section">
                <h2>Join the Health Revolution</h2>
                <div className="social-icons">
                    <a href="https://x.com/VitaliseAI" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
                    <a href="https://www.instagram.com/vitaliseai/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                </div>
                <div className="cta-buttons">
                    <button className="cta-button" onClick={() => navigate('/login')}>Log in</button>
                    <button className="secondary-button" onClick={() => navigate('/signup')}>Sign up now</button>
                </div>
            </div>
        </div>
    );
}

export default Home;
