import React from 'react';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
    const navigate = useNavigate();

    return (
        <div className="welcome-container">
            <h1>Welcome to Our App!</h1>
            <p>Thank you for signing up. Here's some important information about our app...</p>
            <button onClick={() => navigate('/dashboard')}>Continue to Dashboard</button>
        </div>
    );
};

export default Welcome;