// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/NotFound.css */
.notfound {
    color: var(--text-color);
    text-align: center;
    padding: 100px 20px;
  }
  
  .notfound-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .notfound-icon {
    font-size: 80px;
    color: var(--accent-color);
    filter: drop-shadow(0 0 20px var(--accent-color));
    margin-bottom: 20px;
  }
  
  .notfound h1 {
    font-size: 48px;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 10px var(--primary-color));
  }
  
  .notfound p {
    font-size: 18px;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 10px var(--primary-color));
  }
  
  .home-button {
    background-color: var(--primary-color);
    border: 2px solid transparent;
    padding: 15px 30px;
    color: var(--text-color);
    font-size: 18px;
    letter-spacing: 3px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  
  .home-button:hover {
    border: 2px solid var(--primary-color);
    background-color: transparent;
    box-shadow: 0 0 10px var(--primary-color);
    transition: box-shadow 0.3s, background-color 0.3s;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/NotFound.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,0BAA0B;IAC1B,iDAAiD;IACjD,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,kDAAkD;EACpD;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,kDAAkD;EACpD;;EAEA;IACE,sCAAsC;IACtC,6BAA6B;IAC7B,kBAAkB;IAClB,wBAAwB;IACxB,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,sCAAsC;IACtC,6BAA6B;IAC7B,yCAAyC;IACzC,kDAAkD;EACpD","sourcesContent":["/* src/components/NotFound.css */\n.notfound {\n    color: var(--text-color);\n    text-align: center;\n    padding: 100px 20px;\n  }\n  \n  .notfound-content {\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .notfound-icon {\n    font-size: 80px;\n    color: var(--accent-color);\n    filter: drop-shadow(0 0 20px var(--accent-color));\n    margin-bottom: 20px;\n  }\n  \n  .notfound h1 {\n    font-size: 48px;\n    margin-bottom: 20px;\n    filter: drop-shadow(0 0 10px var(--primary-color));\n  }\n  \n  .notfound p {\n    font-size: 18px;\n    margin-bottom: 20px;\n    filter: drop-shadow(0 0 10px var(--primary-color));\n  }\n  \n  .home-button {\n    background-color: var(--primary-color);\n    border: 2px solid transparent;\n    padding: 15px 30px;\n    color: var(--text-color);\n    font-size: 18px;\n    letter-spacing: 3px;\n    cursor: pointer;\n    border-radius: 25px;\n    transition: all 0.3s ease;\n  }\n  \n  .home-button:hover {\n    border: 2px solid var(--primary-color);\n    background-color: transparent;\n    box-shadow: 0 0 10px var(--primary-color);\n    transition: box-shadow 0.3s, background-color 0.3s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
