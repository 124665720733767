// src/components/CookieSettings.js
import React, { useState, useEffect, useContext } from 'react';
import './CookieSettings.css';
import { AuthContext } from '../context/AuthContext';

import axios from 'axios';

const CookieSettings = () => {
    const { user } = useContext(AuthContext);
    const [cookieConsent, setCookieConsent] = useState(false);
    const [personalData, setPersonalData] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

    useEffect(() => {
        checkCookieConsent();
    }, []);

    useEffect(() => {
        checkCookieConsent();
    }, []);

    const checkCookieConsent = async () => {
        if (user) {
            try {
                const response = await axios.get(`${API_BASE_URL}/check-cookie-consent`, { params: { user_id: user.id } });
                setCookieConsent(response.data.consent);
            } catch (error) {
                console.error('Error checking cookie consent:', error);
            }
        } else {
            setCookieConsent(localStorage.getItem('cookieConsent') === 'true');
        }
    };

    const toggleConsent = async () => {
        const newConsent = !cookieConsent;
        if (user) {
            try {
                await axios.post(`${API_BASE_URL}/update-cookie-consent`, { user_id: user.id, consent: newConsent });
            } catch (error) {
                console.error('Error updating cookie consent:', error);
                return;
            }
        }
        localStorage.setItem('cookieConsent', newConsent.toString());
        setCookieConsent(newConsent);
        if (!newConsent) {
            // Clear analytics data here if needed
        }
    };

    const requestPersonalData = async () => {
        if (user) {
            try {
                const response = await axios.get(`${API_BASE_URL}/get-personal-data`, { params: { user_id: user.id } });
                setPersonalData(response.data);
            } catch (error) {
                console.error('Error fetching personal data:', error);
            }
        } else {
            alert('You need to be logged in to access your personal data.');
        }
    };

    const requestDataErasure = async () => {
        if (user) {
            if (window.confirm('Are you sure you want to request erasure of your personal data? This action cannot be undone.')) {
                try {
                    await axios.post(`${API_BASE_URL}/request-data-erasure`, { user_id: user.id });
                    alert('Your data erasure request has been submitted. We will process it shortly.');
                } catch (error) {
                    console.error('Error requesting data erasure:', error);
                }
            }
        } else {
            alert('You need to be logged in to request data erasure.');
        }
    };

    return (
        <div className="cookie-page">
            <h1 className="cookie-header">Cookie Settings</h1>
            <div className="cookie-content">
                <div className="cookie-section">
                    <h2>Cookie Consent</h2>
                    <p>We use cookies to enhance your experience and analyze site traffic.</p>
                    <label className="switch">
                        <input type="checkbox" checked={cookieConsent} onChange={toggleConsent} />
                        <span className="slider round"></span>
                    </label>
                    <span>{cookieConsent ? 'Cookies are enabled' : 'Cookies are disabled'}</span>
                </div>
                <div className="cookie-section">
                    <h2>Your Data Rights</h2>
                    <button onClick={requestPersonalData} className="data-button">Request Personal Data</button>
                    <button onClick={requestDataErasure} className="data-button">Request Data Erasure</button>
                </div>
                {personalData && (
                    <div className="cookie-section">
                        <h2>Your Personal Data</h2>
                        <pre>{JSON.stringify(personalData, null, 2)}</pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CookieSettings;