import React, { useState, useEffect } from 'react';
import './UserProfile.css';

const UserProfile = () => {
  const [profile, setProfile] = useState({
    age: '',
    height: '',
    fitnessLevel: 5,
    dietaryRestrictions: '',
    healthConditions: '',
    goals: [],
  });

  useEffect(() => {
    // Fetch user profile from API
    // This is a placeholder, replace with actual API call
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit profile changes to API
  };

  return (
    <div className="profile-content">
      <h1>User Profile</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="age">Age:</label>
          <input
            type="number"
            id="age"
            value={profile.age}
            onChange={(e) => setProfile({...profile, age: e.target.value})}
          />
        </div>
        <div>
          <label htmlFor="height">Height:</label>
          <input
            type="number"
            id="height"
            value={profile.height}
            onChange={(e) => setProfile({...profile, height: e.target.value})}
          />
        </div>
        {/* Add more form fields for other profile attributes */}
        <button type="submit">Save Profile</button>
      </form>
    </div>
  );
};

export default UserProfile;