// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import Logo from '../assets/Logo_Colortext_whitetext.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <hr className="footer-line" />
                <div className="footer-sections">
                    <div className="footer-column">
                        <img src={Logo} alt="Logo" className="footer-logo" />
                        <p><a href="mailto:info@vitaliseai.com" target="_blank" rel="noopener noreferrer">info@vitaliseai.com</a></p>
                        <p><a href="/privacy-policy" rel="noopener noreferrer">Privacy Policy</a></p>
                        <p>All rights reserved</p>
                    </div>
                    <div className="footer-column footer-column-wide">
                        <p><a href="https://mikrasventures.com/" target="_blank" rel="noopener noreferrer">Mikras Ventures ApS</a> | Vitalise.ai</p>
                        <p>Tolderlundsvej 88, 1. tv.</p>
                        <p>5000 Odense C</p>
                        <p>CVR: 44658054</p>
                    </div>
                    <div className="footer-column">
                        <p><strong>Product</strong></p>
                        <p><a href="/">Home</a></p>
                        <p><a href="/pricing">Pricing</a></p>
                        <p><a href="/news">News</a></p>
                        <p><a href="/docs" target="_blank" rel="noopener noreferrer">Docs <span className="arrow">↗</span></a></p>
                    </div>
                    <div className="footer-column">
                        <p><strong>Feedback</strong></p>
                        <p><a href="/faq">FAQ</a></p>
                        <p><a href="https://vitalise.canny.io/" target="_blank" rel="noopener noreferrer">Roadmap <span className="arrow">↗</span></a></p>
                        <p><a href="https://vitalise.canny.io/feature-requests" target="_blank" rel="noopener noreferrer">Feature Request <span className="arrow">↗</span></a></p>
                    </div>
                    <div className="footer-column">
                        <p><strong>Socials</strong></p>
                        <div className="social-icons-footer">
                            <a href="https://x.com/VitaliseAI" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
                            <a href="https://www.instagram.com/vitaliseai/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
