// src/components/FAQ.js
import React, { useState, useRef, useEffect } from 'react';
import './FAQ.css';

const faqData = [
    {
        question: "What is Vitalise?",
        answer: "Vitalise is a transformative solution in the healthcare landscape, leveraging advanced AI and natural language processing to empower individuals with personalized health management."
    },
    {
        question: "How does Vitalise handle data privacy?",
        answer: "Vitalise ensures that all user data is securely stored and processed, complying with the highest standards of data privacy and protection."
    },
    {
        question: "What kind of health plans can Vitalise generate?",
        answer: "Vitalise can generate personalized workout, diet plans, yoga routines, mental health plans, and more, based on your unique health data and preferences."
    },
    {
        question: "How does Vitalise integrate with wearables?",
        answer: "Vitalise integrates seamlessly with various wearable devices to gather real-time health data, which it uses to provide accurate and personalized health advice."
    },
    {
        question: "Can I customize my digital health assistant?",
        answer: "Yes, you can customize your digital health assistant by selecting trusted health experts, research materials, and podcasts to shape the assistant's knowledge base."
    }
];

const FAQ = () => {
    const [openQuestion, setOpenQuestion] = useState(null);
    const [heights, setHeights] = useState({});
    const refs = useRef({});

    useEffect(() => {
        const newHeights = {};
        Object.keys(refs.current).forEach(key => {
            newHeights[key] = refs.current[key].scrollHeight;
        });
        setHeights(newHeights);
    }, []);

    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className="faq-page">
            <h1 className="faq-header">Frequently Asked Questions</h1>
            <p className="faq-subheader">
                Here are answers to some of the most asked questions about Vitalise.ai
            </p>
            <div className="faq-content">
                {faqData.map((faq, index) => (
                    <div className="faq-section" key={index}>
                        <button 
                            className={`faq-question ${openQuestion === index ? 'open' : ''}`}
                            onClick={() => toggleQuestion(index)}
                            aria-expanded={openQuestion === index}
                        >
                            {faq.question}
                            <span className="faq-icon">{openQuestion === index ? '−' : '+'}</span>
                        </button>
                        <div 
                            className={`faq-answer ${openQuestion === index ? 'open' : ''}`}
                            ref={el => refs.current[index] = el}
                            style={{height: openQuestion === index ? heights[index] + 'px' : '0px'}}
                        >
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;