import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserSettings.css';
import axios from 'axios';

const API_BASE_URL = 'https://vitaliseai-backend-s4fue.ondigitalocean.app/';

const UserSettings = () => {
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState('General');
  const [settings, setSettings] = useState({
    language: 'en',
    display_setting: 'light',
    voice_setting: '1',
    voice_speed_setting: 1,
    autoplaybackaudio_setting: false,
  });

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await axios.post(`${API_BASE_URL}/get_user_settings`, { user_id: userId }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setSettings(response.data);
      } else {
        console.error('Failed to fetch user settings');
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const handleSaveSettings = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await axios.post(`${API_BASE_URL}/update_user_settings`, {
        user_id: userId,
        ...settings,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        alert('Settings saved successfully');
      } else {
        alert('Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings');
    }
  };

  const handleForgetMe = () => {
    if (window.confirm('Are you sure you want to delete all your data? This action cannot be undone.')) {
      // Implement forget me functionality
      console.log('User data deleted');
    }
  };

  const playbackVoiceMap = {
    '1': 'Echo',
    '2': 'Alloy',
    '3': 'Fable',
    '4': 'Onyx',
    '5': 'Nova',
    '6': 'Shimmer',
  };

  return (
    <div className="settings-container">
      <h1>User Settings</h1>
      <div className="settings-nav">
        <button 
          className={currentSection === 'General' ? 'active' : ''} 
          onClick={() => setCurrentSection('General')}
        >
          General
        </button>
        <button 
          className={currentSection === 'Audio' ? 'active' : ''} 
          onClick={() => setCurrentSection('Audio')}
        >
          Audio
        </button>
        
        <button 
          className={currentSection === 'Account' ? 'active' : ''} 
          onClick={() => setCurrentSection('Account')}
        >
          Account
        </button>
      </div>
      <div className="settings-content">
        {currentSection === 'General' && (
          <div>
            <h2>General Settings</h2>
            <div className="setting-item">
              <label htmlFor="language">Language:</label>
              <select
                id="language"
                value={settings.language}
                onChange={(e) => setSettings({...settings, language: e.target.value})}
              >
                <option value="en">English</option>
                <option value="dk">Danish</option>
              </select>
            </div>
            <div className="setting-item">
              <label htmlFor="display_setting">Theme:</label>
              <select
                id="display_setting"
                value={settings.display_setting}
                onChange={(e) => setSettings({...settings, display_setting: e.target.value})}
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
                <option value="color">Color</option>
                <option value="pink">Pink</option>
                <option value="cyber">Cyber</option>
              </select>
            </div>
          </div>
        )}
        {currentSection === 'Audio' && (
          <div>
            <h2>Audio Settings</h2>
            <div className="setting-item">
              <label htmlFor="voice_setting">Playback Voice:</label>
              <select
                id="voice_setting"
                value={settings.voice_setting}
                onChange={(e) => setSettings({...settings, voice_setting: e.target.value})}
              >
                {Object.entries(playbackVoiceMap).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
                ))}
              </select>
            </div>
            <div className="setting-item">
              <label htmlFor="voice_speed_setting">Playback Speed: {settings.voice_speed_setting.toFixed(1)}x</label>
              <input
                type="range"
                id="voice_speed_setting"
                min="0.5"
                max="2.5"
                step="0.1"
                value={settings.voice_speed_setting}
                onChange={(e) => setSettings({...settings, voice_speed_setting: parseFloat(e.target.value)})}
              />
            </div>
            <div className="setting-item">
              <label htmlFor="autoplaybackaudio_setting">Auto-play Audio:</label>
              <input
                type="checkbox"
                id="autoplaybackaudio_setting"
                checked={settings.autoplaybackaudio_setting}
                onChange={(e) => setSettings({...settings, autoplaybackaudio_setting: e.target.checked})}
              />
            </div>
          </div>
        )}
        {currentSection === 'Account' && (
          <div>
            <h2>Account Settings</h2>
            <div className="setting-item">
              <label>Subscription Plan:</label>
              <p>Current Plan: free</p>
              <button className="upgrade-button" onClick={() => console.log("Upgrade Subscription")}>
                Upgrade Subscription
              </button>
            </div>

            <div className="setting-item">
              <h3>Delete Account</h3>
              <p>This will delete all your data permanently from our database. It will not be recoverable.</p>
              <button className="forget-me-button" onClick={handleForgetMe}>
                Delete My Account
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="settings-actions">
        <button className="save-button" onClick={handleSaveSettings}>Save Settings</button>
        <button className="cancel-button" onClick={() => navigate('/dashboard')}>Cancel</button>
      </div>
    </div>
  );
};

export default UserSettings;