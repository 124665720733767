import React from 'react';
import { bouncy } from 'ldrs';

// Configure the bouncy loader
bouncy.register();

const LoadingAnim = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '300px'
    }}>
      <l-bouncy
        size="45"
        speed="1.75"
        color="var(--primary-color)"
      ></l-bouncy>
    </div>
  );
};

export default LoadingAnim;