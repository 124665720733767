// src/docs/Docs.js
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom';
import './Docs.css';
import { useMediaQuery } from 'react-responsive';
import { FaBars, FaList } from 'react-icons/fa';

import DocsHeader from './DocsHeader';
import DocsFooter from './DocsFooter';

import Introduction from './pages/Introduction';
import GettingStarted from './pages/GettingStarted';
import Settings from './pages/Settings';
// ... other imports

import { SearchContext } from './helpers/SearchContext';
import { preloadPages } from './helpers/preloadContent';

const Docs = () => {
  const location = useLocation();
  const [sections, setSections] = useState([]);
  const { addPageContent } = useContext(SearchContext);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const preloadAllPages = useCallback(() => {
    const preloadedContent = preloadPages();
    preloadedContent.forEach(({ path, sections }) => {
      addPageContent(path, sections);
    });
  }, [addPageContent]);

  useEffect(() => {
    preloadAllPages();
  }, [preloadAllPages]);

  useEffect(() => {
    const updateSections = () => {
      const sectionElements = document.querySelectorAll('.docs-content h1, .docs-content h2, .docs-content h3');
      const sectionList = Array.from(sectionElements).map(section => ({
        id: section.id,
        text: section.innerText,
        tagName: section.tagName
      }));
      setSections(sectionList);
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        const link = document.querySelector(`a[href="#${id}"]`);
        if (link) {
          if (entry.isIntersecting) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });

    const currentSections = document.querySelectorAll('.docs-content h1, .docs-content h2, .docs-content h3');
    currentSections.forEach((section) => {
      observer.observe(section);
    });

    updateSections();

    return () => {
      observer.disconnect();
      setSections([]);
    };
  }, [location]);

  const handleSidebarLinkClick = (id) => {
    const element = document.getElementById(id);
    const headerOffset = 80; // Adjust this value based on your header height
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const toggleLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
    setRightSidebarOpen(false);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
    setLeftSidebarOpen(false);
  };

  const closeSidebars = () => {
    setLeftSidebarOpen(false);
    setRightSidebarOpen(false);
  };

  const handleNavLinkClick = () => {
    if (isSmallScreen) {
      setLeftSidebarOpen(false);
    }
  };

  return (
    <div className="docs-wrapper">
      <DocsHeader sections={sections} />
      <div className="docs-container">
        <div className={`docs-sidebar-left ${isSmallScreen && leftSidebarOpen ? 'open' : ''}`}>
          <nav>
            <ul>
              <li><NavLink to="/docs/introduction" className={({ isActive }) => isActive ? 'active' : ''} onClick={handleNavLinkClick}>Introduction</NavLink></li>
              <li><NavLink to="/docs/getting-started" className={({ isActive }) => isActive ? 'active' : ''} onClick={handleNavLinkClick}>Getting Started</NavLink></li>
              <li><NavLink to="/docs/settings" className={({ isActive }) => isActive ? 'active' : ''} onClick={handleNavLinkClick}>Settings</NavLink></li>
              {/* Add other links with onClick={handleNavLinkClick} */}
            </ul>
          </nav>
        </div>
        <div className="docs-content" onClick={isSmallScreen ? closeSidebars : undefined}>
          <Routes>
            <Route path="/" element={<Navigate to="/docs/introduction" replace />} />
            <Route path="/introduction" element={<Introduction />} />
            <Route path="/getting-started" element={<GettingStarted />} />
            <Route path="/settings" element={<Settings />} />
            {/* Add other routes */}
          </Routes>
        </div>
        {sections.length > 0 && (
          <div className={`docs-sidebar-right ${isSmallScreen && rightSidebarOpen ? 'open' : ''}`}>
            <h2>On This Page</h2>
            <nav>
              <ul>
                {sections.map(section => (
                  <li key={section.id} className={section.tagName === 'H3' ? 'indent' : ''}>
                    <a href={`#${section.id}`} onClick={(e) => { e.preventDefault(); handleSidebarLinkClick(section.id); }}>
                      {section.text}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
        {isSmallScreen && (leftSidebarOpen || rightSidebarOpen) && (
          <div className="sidebar-backdrop" onClick={closeSidebars}></div>
        )}
        {isSmallScreen && (
          <>
            <button className="sidebar-toggle left" onClick={toggleLeftSidebar}>
              <FaBars />
            </button>
            <button className="sidebar-toggle right" onClick={toggleRightSidebar}>
              <FaList />
            </button>
          </>
        )}
      </div>
      <DocsFooter />
    </div>
  );
};

export default Docs;