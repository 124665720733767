// src/app/components/UserSidebar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './UserSidebar.css';
import { FaHome, FaRobot, FaUser, FaCog } from 'react-icons/fa';

const UserSidebar = () => {
  const location = useLocation();

  return (
    <div className="user-sidebar">
      <nav>
        <ul>
          <li className={location.pathname === '/dashboard' ? 'active' : ''}>
            <Link to="/dashboard"><FaHome /> Dashboard</Link>
          </li>
          <li className={location.pathname === '/app-assistants' ? 'active' : ''}>
            <Link to="/app-assistants"><FaRobot /> Assistants</Link>
          </li>
          <li className={location.pathname === '/profile' ? 'active' : ''}>
            <Link to="/profile"><FaUser /> Profile</Link>
          </li>
          <li className={location.pathname === '/settings' ? 'active' : ''}>
            <Link to="/settings"><FaCog /> Settings</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default UserSidebar;