// src/control/config/pieChartConfig.js
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const generateGradientColors = (count) => {
  if (count === 1) {
    return ['#189AB4']; // Primary color for single item
  }
  const startColor = { r: 24, g: 154, b: 180 }; // #189AB4
  const endColor = { r: 255, g: 87, b: 51 }; // #FF5733
  return Array.from({ length: count }, (_, index) => {
    const gradient = index / (count - 1);
    const r = Math.round(startColor.r + gradient * (endColor.r - startColor.r));
    const g = Math.round(startColor.g + gradient * (endColor.g - startColor.g));
    const b = Math.round(startColor.b + gradient * (endColor.b - startColor.b));
    return `rgb(${r},${g},${b})`;
  });
};

export const getFilesPieChartData = (personData) => {
    if (!personData || personData.length === 0) {
      return {
        dataForCategoryChart: {
          labels: [],
          datasets: [{ data: [], backgroundColor: [] }]
        },
        dataForItemChart: {
          labels: [],
          datasets: [{ data: [], backgroundColor: [] }]
        }
      };
    }
  
    // Chart 1: Distribution of character counts for each category
    const categoryLabels = personData.map(cat => cat.category);
    const categoryData = personData.map(cat => cat.character_count);
    const categoryColors = generateGradientColors(categoryLabels.length);
  
    const dataForCategoryChart = {
      labels: categoryLabels,
      datasets: [{
        data: categoryData,
        backgroundColor: categoryColors,
      }],
    };
  
    // Chart 2: Distribution of character counts for each category item across all categories
    const itemLabels = personData.flatMap(cat => cat.items.map(item => item.item));
    const itemData = personData.flatMap(cat => cat.items.map(item => item.character_count));
    const itemColors = generateGradientColors(itemLabels.length);
  
    const dataForItemChart = {
      labels: itemLabels,
      datasets: [{
        data: itemData,
        backgroundColor: itemColors,
      }],
    };
  
    return {
      dataForCategoryChart,
      dataForItemChart
    };
};

export const filesPieChartOptions = {
    responsive: true,
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    const { dataset, dataIndex } = context;
                    const person = dataset.personData ? dataset.personData[dataIndex] : null;
                    if (person) {
                        return `${person.name} ${person.surname} (ID: ${person.id}): ${context.raw}`;
                    } else {
                        return `${context.label}: ${context.raw}`;
                    }
                }
            }
        },
        legend: {
            display: false,  // Disable the legend
        }
    }
};