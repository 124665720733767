// src/docs/SearchBar.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import './SearchBar.css';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from './helpers/SearchContext';
import { useMediaQuery } from 'react-responsive';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [filteredSections, setFilteredSections] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [hideResults, setHideResults] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { searchIndex } = useContext(SearchContext);
  const navigate = useNavigate();
  const searchResultsRef = useRef();
  const searchBarRef = useRef();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (query.length > 0 && !hideResults) {
      const queryWords = query.toLowerCase().split(/\s+/).filter(word => word.length > 0);
      
      const filtered = searchIndex.filter(page => 
        queryWords.every(word => 
          page.text.toLowerCase().includes(word) || 
          page.content.toLowerCase().includes(word)
        )
      );
      
      setFilteredSections(filtered);
      setShowResults(true);
    } else {
      setFilteredSections([]);
      setShowResults(false);
    }
  }, [query, searchIndex, hideResults]);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setHideResults(false); // Reset hideResults when typing
  };

  const highlightMatch = (text, query) => {
    const queryWords = query.toLowerCase().split(/\s+/).filter(word => word.length > 0);
    let highlightedText = text;
  
    queryWords.forEach(word => {
      const regex = new RegExp(`(${word})`, 'gi');
      highlightedText = highlightedText.replace(regex, '<mark>$1</mark>');
    });
  
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };

  const handleLinkClick = (path, id) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        const headerOffset = 80; // Adjust this value based on your header height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }, 0);
    setQuery('');
    setShowResults(false);
  };

  const handleClickOutside = (event) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(event.target) &&
      !searchBarRef.current.contains(event.target)
    ) {
      setHideResults(true);
      setShowResults(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (query.length > 0) {
      setShowResults(true);
      setHideResults(false);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setQuery('');
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="search-bar" ref={searchBarRef}>
      <input
        type="text"
        placeholder={isSmallScreen ? "Search doc." : "Search documentation..."}
        value={query}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {isFocused && query.length > 0 && !isSmallScreen && (
        <div className="esc-box">ESC</div>
      )}
      {showResults && filteredSections.length > 0 && (
        <div className="search-results" ref={searchResultsRef}>
          {filteredSections.map((section, index) => (
            <div className="search-result-item" key={index} onClick={() => handleLinkClick(section.path, section.id)}>
              <strong>{highlightMatch(section.text, query)}</strong>
              <div>{highlightMatch(section.content, query)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;