// src/app/Signup.js
import React, { useContext, useState } from 'react';
import './LoginSignup.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaGoogle } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import { useGoogleLogin } from '@react-oauth/google';
import { GAEvent } from '../GoogleAnalytics';

import axios from 'axios';

import Logo from '../assets/Logo_Colortext_whitetext.png';
import LogoOutlineGlow from '../assets/backgrounds/logo_outline_glow.png';

const Signup = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

    const onSubmit = async (data) => {
        setErrorMessage(''); // Clear any previous error messages

        try {
            const response = await axios.post(`${API_BASE_URL}/create-user`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                const result = response.data;
                login({
                    user_id: result.user_id,
                    email: result.email,
                    user_version: result.user_version,
                    name: result.name,
                    surname: result.surname
                });
                navigate('/welcome');
                GAEvent("User", "Signup", "Successful");
            } else {
                setErrorMessage('Signup failed. Please try again.');
                GAEvent("User", "Signup", "Failed");
            }
        } catch (error) {
            console.error('Error:', error);
            if (error.response) {
                setErrorMessage('Signup failed: ' + (error.response.data.error || 'An error occurred'));
                GAEvent("User", "Signup", "Failed");
            } else {
                setErrorMessage('Network error. Please check your connection and try again.');
                GAEvent("User", "Signup", "Failed");
            }
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        setErrorMessage(''); // Clear any previous error messages
        try {
            console.log('Google response:', credentialResponse);
            const response = await axios.post(`${API_BASE_URL}/auth/google/callback`, {
                access_token: credentialResponse.access_token
            });
            login(response.data);
            navigate('/dashboard');
            GAEvent("User", "Google Signup", "Successful");
        } catch (error) {
            console.error('Google login/signup failed:', error);
            setErrorMessage('Google login/signup failed. Please try again.');
            GAEvent("User", "Google Signup", "Failed");
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: () => {
            console.log('Signup Failed');
            setErrorMessage('Google signup failed. Please try again.');
            GAEvent("User", "Google Signup", "Failed");
        }
    });

    return (
        <div className="signup-container">
            <div className="background-logo">
                <img src={LogoOutlineGlow} alt="Background Logo" />
            </div>
            <div className="signup-content">
                <Link to="/" className="login-logo">
                    <img src={Logo} alt="Logo" />
                </Link>
                <div className="signup-card">
                    <h1>Sign Up</h1>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button onClick={() => googleLogin()} className="oauth-button google-button">
                        <FaGoogle className="oauth-icon-google" /> <span>Continue with Google</span>
                    </button>
                    <p className="or-text">Or</p>
                    <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group half-width">
                                <input
                                    type="text"
                                    placeholder=" "
                                    {...register('name', { required: 'Name is required' })}
                                    required
                                />
                                <label>Name</label>
                                {errors.name && <span className="error-message">{errors.name.message}</span>}
                            </div>
                            <div className="form-group half-width">
                                <input
                                    type="text"
                                    placeholder=" "
                                    {...register('surname', { required: 'Surname is required' })}
                                    required
                                />
                                <label>Surname</label>
                                {errors.surname && <span className="error-message">{errors.surname.message}</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder=" "
                                {...register('email', { required: 'Email is required' })}
                                required
                            />
                            <label>Email Address</label>
                            {errors.email && <span className="error-message">{errors.email.message}</span>}
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder=" "
                                {...register('password', { required: 'Password is required' })}
                                required
                            />
                            <label>Password</label>
                            {errors.password && <span className="error-message">{errors.password.message}</span>}
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder=" "
                                {...register('confirmPassword', { required: 'Confirm Password is required' })}
                                required
                            />
                            <label>Confirm Password</label>
                            {errors.confirmPassword && <span className="error-message">{errors.confirmPassword.message}</span>}
                        </div>
                        <button type="submit" className="signup-button">Sign Up</button>
                    </form>
                    <p className="privacy-policy">Our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p className="login-link">
                        Already have an account? <a href="/login">Login</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Signup;