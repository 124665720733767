// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CookieConsent from "react-cookie-consent";
import { initGA, GAPageView } from './GoogleAnalytics';
import { usePostHog } from 'posthog-js/react'

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTop from './components/ScrollToTop';
import LaunchingSoon from './components/LaunchingSoon';

// Screens
import Home from './screens/Home';
import Experts from './screens/Experts';
import News from './screens/News';
import Pricing from './screens/Pricing';

// Pages
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookieSettings from './pages/CookieSettings';
import TermsOfService from './pages/TermsOfService';
import FAQ from './pages/FAQ';

// App
import Login from './app/Login';
import Signup from './app/Signup';
import Welcome from './app/Welcome';
import Account from './app/Account';
import UserScreen from './app/components/UserScreen';
import UserDashboard from './app/screens/UserDashboard';
import UserAssistants from './app/screens/UserAssistants';
import UserProfile from './app/screens/UserProfile';
import UserSettings from './app/screens/UserSettings';

// Docs
import Docs from './docs/Docs';
import { SearchProvider } from './docs/helpers/SearchContext';


const AppContent = ({ showLaunchingSoon, isAdmin, onAdminLogin }) => {
  const { user, isLoading } = useContext(AuthContext);
  const location = useLocation();
  const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/signup' || location.pathname.startsWith('/docs');
  const noPaddingClass = hideHeaderFooter ? 'no-padding' : '';

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  if (showLaunchingSoon && !isAdmin && location.pathname === '/') {
    return <LaunchingSoon onAdminLogin={onAdminLogin} />;
  }

  return (
    <div className={`content-wrapper ${noPaddingClass}`}>
      {!hideHeaderFooter && <Header />}
      <Routes>
        <Route path="/dashboard" element={user ? <UserScreen><UserDashboard /></UserScreen> : <Navigate to="/login" replace />} />
        <Route path="/profile" element={user ? <UserScreen><UserProfile /></UserScreen> : <Navigate to="/login" replace />} />
        <Route path="/settings" element={user ? <UserScreen><UserSettings /></UserScreen> : <Navigate to="/login" replace />} />
        <Route path="/app-assistants" element={user ? <UserScreen><UserAssistants /></UserScreen> : <Navigate to="/login" replace />} />

        <Route path="/account" element={user ? <Account /> : <Navigate to="/login" replace />} />

        <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Home />} />
        {/* Screens */}
        <Route path="/experts" element={<Experts />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:articleId" element={<News />} />
        <Route path="/pricing" element={<Pricing />} />

        {/* Pages */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-settings" element={<CookieSettings />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/faq" element={<FAQ />} />

        {/* App */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={user ? <Welcome /> : <Navigate to="/login" replace />} />

        {/* Docs */}
        <Route path="/docs/*" element={<Docs />} />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
};

function App() {
  const { checkAuthStatus } = useContext(AuthContext);
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(false);
  const posthog = usePostHog()
  const location = useLocation();

  const [showLaunchingSoon, setShowLaunchingSoon] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const launchingState = localStorage.getItem('showLaunchingSoon');
    if (launchingState !== null) {
      setShowLaunchingSoon(JSON.parse(launchingState));
    }
    if (checkAuthStatus) {
      checkAuthStatus();
    }
  }, [checkAuthStatus]);

  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview')
    }
  }, [posthog])

  const handleAdminLogin = (username, password) => {
    if (username === 'admin' && password === 'admin123') {
      setIsAdmin(true);
    }
  };

  const toggleLaunchingSoon = () => {
    const newState = !showLaunchingSoon;
    setShowLaunchingSoon(newState);
    localStorage.setItem('showLaunchingSoon', JSON.stringify(newState));
  };

  const initAnalytics = () => {
    initGA();
    setAnalyticsInitialized(true);
  };

  const handleAcceptCookies = () => {
    setCookieConsent(true);
    localStorage.setItem('cookieConsent', 'true');
    initAnalytics();
    posthog.opt_in_capturing(); // Opt-in to PostHog tracking
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ScrollIndicator />
      <ScrollToTop />
      <SearchProvider>
        {analyticsInitialized && <GAPageView />}
        <AppContent showLaunchingSoon={showLaunchingSoon} isAdmin={isAdmin} onAdminLogin={handleAdminLogin} />
      </SearchProvider>
      {cookieConsent && <GAPageView />}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{ 
          background: "var(--secondary-color)",
          fontSize: "16px",
          padding: "5px",
          alignItems: "center",
          justifyContent: "space-between"
        }}
        buttonStyle={{ 
          background: "#189AB4",
          color: "#ECEFF1",
          fontSize: "14px",
          padding: "5px 20px",
          marginRight: "40px",
          borderRadius: "10px"
        }}
        contentStyle={{
          flex: "1 0 300px",
          margin: "15px"
        }}
        expires={365}
        onAccept={handleAcceptCookies}
      >
        <span style={{ fontSize: "16px", fontFamily: "Montserrat, sans-serif" }}>
          We use cookies to make your experience sweeter! 🍪 Help us improve by accepting our tasty digital treats.
          <br />
          <a href="/privacy-policy" style={{ color: "var(--accent-color)" }} target="_blank" rel="noopener noreferrer">Learn more</a>
        </span>
      </CookieConsent>
      {isAdmin && (
        <button 
          onClick={toggleLaunchingSoon}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 9999,
            padding: '10px',
            backgroundColor: '#189AB4',
            color: '#ECEFF1',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          {showLaunchingSoon ? 'Disable Launching Soon' : 'Enable Launching Soon'}
        </button>
      )}
    </GoogleOAuthProvider>
  );
}

export default App;