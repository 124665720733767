// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-modal-content {
    max-width: 100%;
    max-height: 100%;
    position: relative;
  }
  
  .image-modal-content img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
  
  .image-modal-close {
    position: absolute;
    top: -40px;
    right: -40px;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/docs/helpers/ImageModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;EACjB","sourcesContent":[".image-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .image-modal-content {\n    max-width: 100%;\n    max-height: 100%;\n    position: relative;\n  }\n  \n  .image-modal-content img {\n    max-width: 100%;\n    max-height: 80vh;\n    object-fit: contain;\n  }\n  \n  .image-modal-close {\n    position: absolute;\n    top: -40px;\n    right: -40px;\n    background: none;\n    border: none;\n    color: white;\n    font-size: 30px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
