// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/docs/pages/Pages.css */


/* GettingStarted Page */
.page-image {
  max-width: 20%;
  height: auto;
  margin: 20px;
  margin-right: 50px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.page-image:hover {
  transform: scale(1.05);
}

.getting-started-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.getting-started-text {
  max-width: 75%;
}


/* Settings Page */

.settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.settings-text {
  max-width: 75%;
}`, "",{"version":3,"sources":["webpack://./src/docs/pages/Pages.css"],"names":[],"mappings":"AAAA,6BAA6B;;;AAG7B,wBAAwB;AACxB;EACE,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;;AAGA,kBAAkB;;AAElB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* src/docs/pages/Pages.css */\n\n\n/* GettingStarted Page */\n.page-image {\n  max-width: 20%;\n  height: auto;\n  margin: 20px;\n  margin-right: 50px;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: transform 0.3s ease;\n}\n\n.page-image:hover {\n  transform: scale(1.05);\n}\n\n.getting-started-row {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 20px 0;\n}\n\n.getting-started-text {\n  max-width: 75%;\n}\n\n\n/* Settings Page */\n\n.settings-row {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 10px 0;\n}\n\n.settings-text {\n  max-width: 75%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
