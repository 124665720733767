import React, { useEffect, useRef, useState } from 'react';
import UserSidebar from './UserSidebar';
import './UserScreen.css';

const UserScreen = ({ children }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setContentHeight(`${contentRef.current.offsetHeight}px`);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [children]);

  return (
    <div className="user-screen-wrapper">
      <div className="user-screen-container" style={{ minHeight: contentHeight }}>
        <UserSidebar />
        <div className="user-screen-content" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default UserScreen;