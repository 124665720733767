// src/docs/DocsHeader.js
import React from 'react';
import './DocsHeader.css';
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo_Colortext_whitetext.png';
import LogoSmall from '../assets/Icon_App.png';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import SearchBar from './SearchBar';

const DocsHeader = ({ sections, searchIndex }) => {
  return (
    <header className="header">
      <div className="docs-header">
        <div className="logo">
          <Link to="/docs">
            <img src={Logo} alt="Vitalise.ai Documentation" className="logo-large" />
            <img src={LogoSmall} alt="Vitalise.ai Documentation" className="logo-small" />
          </Link>
        </div>
        <div className="right-section">
          <nav className="docs-nav">
            <p><a href="/" target="_blank" rel="noopener noreferrer">Home <span className="arrow">↗</span></a></p>
            <p><a href="news" target="_blank" rel="noopener noreferrer">News <span className="arrow">↗</span></a></p>
            <p><a href="faq" target="_blank" rel="noopener noreferrer">FAQ <span className="arrow">↗</span></a></p>
          </nav>
          <SearchBar searchIndex={searchIndex} />
          <div className="social-icons">
            <a href="https://x.com/VitaliseAI" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
            <a href="https://www.instagram.com/vitaliseai/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DocsHeader;
