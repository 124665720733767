// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react'

import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const posthog = usePostHog()

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

    const identifyUser = useCallback((userProperties) => {
      if (posthog && userProperties) {
        posthog.identify(userProperties.email);
        posthog.capture('$set', {
          $set: {
            email: userProperties.email,
            user_version: userProperties.user_version,
            name: userProperties.name,
          }
        });
      }
      console.log("User identified with PostHog with following properties: ", userProperties);
    
      if (window.gtag && userProperties) {
        window.gtag('set', 'user_properties', userProperties);
      }
    }, [posthog]);

    const checkAuthStatus = useCallback(() => {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        const storedUser = JSON.parse(localStorage.getItem('user'));
        
        if (token && storedUser) {
          setUser(storedUser);
          identifyUser(storedUser);
        } else {
          setUser(null);
          posthog.reset();
        }
        setIsLoading(false);
    }, [identifyUser, posthog]);

    useEffect(() => {
      checkAuthStatus();
    }, [checkAuthStatus]);

    const login = useCallback((userData) => {
        setUser(userData);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData));

        const consent = localStorage.getItem('cookieConsent') === 'true';
        axios.post(`${API_BASE_URL}/update-cookie-consent`, { user_id: userData.user_id, consent });

        identifyUser(userData);

        navigate('/dashboard');
    }, [identifyUser, navigate, API_BASE_URL]);

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        if (identifyUser) {
          identifyUser(null);
          posthog.reset();
        }
        navigate('/'); // Redirect to the home page
    };

    const contextValue = { 
        user, 
        isLoading, 
        checkAuthStatus, 
        login, 
        logout,
        identifyUser
      };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };