// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-screen-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px; /* Add some bottom padding */
}

.user-screen-container {
  display: flex;
  width: 80%;
  max-width: 1200px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.user-screen-content {
  flex-grow: 1;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/UserScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,oBAAoB,EAAE,4BAA4B;AACpD;;AAEA;EACE,aAAa;EACb,UAAU;EACV,iBAAiB;EACjB,yCAAyC;EACzC,mBAAmB;EACnB,0CAA0C;EAC1C,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".user-screen-wrapper {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  padding-bottom: 20px; /* Add some bottom padding */\n}\n\n.user-screen-container {\n  display: flex;\n  width: 80%;\n  max-width: 1200px;\n  background-color: var(--background-color);\n  border-radius: 15px;\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);\n  overflow: hidden;\n}\n\n.user-screen-content {\n  flex-grow: 1;\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
