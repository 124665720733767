// src/components/TermsOfService.js
import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="privacy-page">
            <h1 className="privacy-header">Terms of Service</h1>
            <p className="privacy-subheader">
                Below you can find details on our terms of service
            </p>
            <div className="privacy-content">
                <p className="privacy-date">Last updated: 08/07/2024</p>
                <div className="privacy-section">
                    <h2>tl;dr</h2>
                    <ul>
                        <li>Your data is securely stored in PostgreSQL. We do not snoop, sell, or have any intention to monetize your data.</li>
                        <li>We use Google Analytics for website analytics.</li>
                        <li>We use Sentry for error reporting and debugging.</li>
                    </ul>
                </div>
                <div className="privacy-section">
                    <h2>Information Collection and Use</h2>
                    <p>Vitalise.ai operates the <a href="https://www.vitaliseai.com">https://www.vitaliseai.com</a> website, which provides a personal health management service referred to as "Service" in this document.</p>
                    <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service.</p>
                    <p>By choosing to use our Service, you agree to the collection and use of information in accordance with this policy. The Personal Information we collect is used to provide and improve the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                </div>
                <div className="privacy-section">
                    <h2>Log Data</h2>
                    <p>Whenever you visit our Service, we gather data that your browser sends us, known as Log Data. This data may include details such as your computer’s IP address, browser type and version, the pages of our Service you visit, the time and date of your visit, the duration of time spent on those pages, and other analytical data.</p>
                </div>
                <div className="privacy-section">
                    <h2>Cookies</h2>
                    <p>Cookies are small files containing a string of characters that can be placed on your device that uniquely identify your browser. Our website uses cookies to collect information and to enhance your user experience. You can choose to accept or decline cookies, and be notified when a cookie is being sent to your device. If you choose to refuse cookies, some features of our Service may not function properly.</p>
                </div>
                <div className="privacy-section">
                    <h2>Service Providers</h2>
                    <p>Our Service currently utilizes the following third-party companies:</p>
                    <ul>
                        <li>Google Analytics - for usage analytics - <a href="https://developers.google.com/analytics">https://developers.google.com/analytics</a></li>
                        <li>Sentry - for bug tracking - <a href="https://sentry.io/">https://sentry.io/</a></li>
                        <li>PostgreSQL - for database management - <a href="https://www.postgresql.org/">https://www.postgresql.org/</a></li>
                    </ul>
                </div>
                <div className="privacy-section">
                    <h2>Security</h2>
                    <p>We appreciate your trust in sharing your Personal Information with us, and we are committed to using commercially acceptable methods to protect it. However, please be aware that no method of transmission over the Internet or method of electronic storage is completely secure. While we strive to use strong protection measures, we cannot guarantee absolute security. For additional security, sensitive information such as passwords is hashed.</p>
                </div>
                <div className="privacy-section">
                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy periodically. Therefore, we recommend reviewing this page regularly for any changes. We will notify you of any updates by posting the new Privacy Policy on this page. These changes take effect immediately upon posting.</p>
                </div>
                <div className="privacy-section">
                    <h2>Contact Us</h2>
                    <p>If you have any questions or feedback about our Privacy Policy, please reach out to us at <a href="mailto:contact@vitaliseai.com">contact@vitaliseai.com</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsOfService;
