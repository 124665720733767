// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #189AB4;
  --secondary-color: rgba(58, 89, 114, 0.5);
  --secondary-color-full: #3A5972; /* rename to secondary-color-highlight */
  --secondary-color-solid: #1D2E42;
  --background-color: #000212;
  --background-color-rgb: 0, 2, 18;
  --text-color: #ECEFF1;
  --text-color-rgb: 236, 239, 241;
  --accent-color: #FF5733;
  --accent-color-rgb: 255, 87, 51;
}

.content-wrapper {
  padding-top: 100px; /* based on header height */
}

.content-wrapper.no-padding {
  padding-top: 0;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, Arial, Helvetica;
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: inherit;
  text-decoration: none;
}

/* Hide the default scrollbar for all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {  /* WebKit browsers */
  width: 0;
  height: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,yCAAyC;EACzC,+BAA+B,EAAE,wCAAwC;EACzE,gCAAgC;EAChC,2BAA2B;EAC3B,gCAAgC;EAChC,qBAAqB;EACrB,+BAA+B;EAC/B,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB,EAAE,2BAA2B;AACjD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,uDAAuD;EACvD,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA,gDAAgD;AAChD;EACE,qBAAqB,EAAE,YAAY;EACnC,wBAAwB,GAAG,0BAA0B;AACvD;;AAEA,wBAAwB,oBAAoB;EAC1C,QAAQ;EACR,SAAS;AACX","sourcesContent":[":root {\n  --primary-color: #189AB4;\n  --secondary-color: rgba(58, 89, 114, 0.5);\n  --secondary-color-full: #3A5972; /* rename to secondary-color-highlight */\n  --secondary-color-solid: #1D2E42;\n  --background-color: #000212;\n  --background-color-rgb: 0, 2, 18;\n  --text-color: #ECEFF1;\n  --text-color-rgb: 236, 239, 241;\n  --accent-color: #FF5733;\n  --accent-color-rgb: 255, 87, 51;\n}\n\n.content-wrapper {\n  padding-top: 100px; /* based on header height */\n}\n\n.content-wrapper.no-padding {\n  padding-top: 0;\n}\n\nbody {\n  margin: 0;\n  font-family: \"Montserrat\", sans-serif, Arial, Helvetica;\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n/* Hide the default scrollbar for all elements */\n* {\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n}\n\n*::-webkit-scrollbar {  /* WebKit browsers */\n  width: 0;\n  height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
