import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWithAuth from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react'

// Initialize PostHog
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'always',
  capture_pageview: true // Enable auto-capture of pageviews
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <PostHogProvider client={posthog}>
        <AuthProvider>
          <AppWithAuth />
        </AuthProvider>
      </PostHogProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();