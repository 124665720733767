// src/components/NotFound.js
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notfound">
      <div className="notfound-content">
        <FaExclamationTriangle className="notfound-icon" />
        <h1>404 - Page Not Found</h1>
        <p>Oops! Looks like you took a wrong turn.</p>
        <p>Don't worry, it happens to the best of us. Let's get you back on track.</p>
        <button onClick={() => window.location.href = '/'} className="home-button">Go to Home</button>
      </div>
    </div>
  );
};

export default NotFound;
