// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/PrivacyPolicy.css */
.privacy-page {
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacy-header {
    text-align: center;
    font-size: 56px;
    margin-bottom: 40px;
}

.privacy-subheader {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
    color: var(--text-color);
}

.privacy-content {
    background-color: var(--secondary-color);
    padding: 20px;
    border-radius: 25px;
}

.privacy-date {
    text-align: right;
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.7;
    margin-bottom: 20px;
}

.privacy-section {
    margin-bottom: 20px;
}

.privacy-section h2 {
    font-size: 24px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.privacy-section p, .privacy-section ul {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
}

.privacy-section ul {
    padding-left: 20px;
}

.privacy-section ul li {
    margin-bottom: 10px;
}

.privacy-section a {
    color: var(--accent-color);
    text-decoration: none;
}

.privacy-section a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,wBAAwB;IACxB,yCAAyC;IACzC,aAAa;IACb,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,wCAAwC;IACxC,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/components/PrivacyPolicy.css */\n.privacy-page {\n    color: var(--text-color);\n    background-color: var(--background-color);\n    padding: 20px;\n    width: 60%;\n    margin: 0 auto;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.privacy-header {\n    text-align: center;\n    font-size: 56px;\n    margin-bottom: 40px;\n}\n\n.privacy-subheader {\n    text-align: center;\n    font-size: 18px;\n    margin-bottom: 40px;\n    color: var(--text-color);\n}\n\n.privacy-content {\n    background-color: var(--secondary-color);\n    padding: 20px;\n    border-radius: 25px;\n}\n\n.privacy-date {\n    text-align: right;\n    font-size: 14px;\n    color: var(--text-color);\n    opacity: 0.7;\n    margin-bottom: 20px;\n}\n\n.privacy-section {\n    margin-bottom: 20px;\n}\n\n.privacy-section h2 {\n    font-size: 24px;\n    color: var(--text-color);\n    margin-bottom: 10px;\n}\n\n.privacy-section p, .privacy-section ul {\n    font-size: 16px;\n    color: var(--text-color);\n    line-height: 1.5;\n}\n\n.privacy-section ul {\n    padding-left: 20px;\n}\n\n.privacy-section ul li {\n    margin-bottom: 10px;\n}\n\n.privacy-section a {\n    color: var(--accent-color);\n    text-decoration: none;\n}\n\n.privacy-section a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
