// src/app/Login.js
import React, { useContext, useState } from 'react';
import './LoginSignup.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaGoogle } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { GAEvent } from '../GoogleAnalytics';

import Logo from '../assets/Logo_Colortext_whitetext.png';
import LogoOutlineGlow from '../assets/backgrounds/logo_outline_glow.png';

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
    
    const CANNY_REDIRECT_URL = 'https://canny.io/api/redirects/sso';

    const getQueryParam = (name) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(name);
    };

    const onSubmit = async (data) => {
        console.log('Login attempt:', {
            email: data.email,
            API_BASE_URL: API_BASE_URL
        });

        setErrorMessage(''); // Clear any previous error messages

        try {
            const response = await axios.post(`${API_BASE_URL}/login`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = response.data;
            login({
                user_id: result.user_id,
                email: result.email,
                user_version: result.user_version,
                name: result.name,
                surname: result.surname
            });
            console.log('Login successful with user_id:', result.user_id, 'email:', result.email, 'user_version:', result.user_version, 'name:', result.name, 'surname:', result.surname);

            const redirect = getQueryParam('redirect');
            const companyID = getQueryParam('companyID');

            if (redirect && companyID && redirect.startsWith('https://')) {
                // Generate SSO token
                try {
                    const ssoResponse = await axios.post(`${API_BASE_URL}/generate_sso_token`, 
                        { user_id: result.user_id },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    const { sso_token } = ssoResponse.data;
                    const cannyRedirectURL = `${CANNY_REDIRECT_URL}?companyID=${companyID}&ssoToken=${sso_token}&redirect=${encodeURIComponent(redirect)}`;
                    window.location.assign(cannyRedirectURL);
                } catch (ssoError) {
                    console.error('Failed to generate SSO token', ssoError);
                    navigate('/dashboard');
                }
            } else {
                navigate('/dashboard');
            }

            GAEvent("User", "Login", "Successful");
        } catch (error) {
            console.error('Login failed:', error);
            if (error.response) {
                setErrorMessage('Login failed: ' + (error.response.data.error || 'Invalid email or password'));
            } else {
                setErrorMessage('Network error. Please check your connection and try again.');
            }

            GAEvent("User", "Login", "Failed");
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        setErrorMessage(''); // Clear any previous error messages
        try {
            console.log('Google response:', credentialResponse);
            const response = await axios.post(`${API_BASE_URL}/auth/google/callback`, {
                access_token: credentialResponse.access_token
            });
            login(response.data);
            navigate('/dashboard');

            GAEvent("User", "Google Login", "Successful");
        } catch (error) {
            console.error('Google login/signup failed:', error);
            setErrorMessage('Google login/signup failed. Please try again.');

            GAEvent("User", "Google Login", "Failed");
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: () => {
            console.log('Login Failed');
            setErrorMessage('Google login failed. Please try again.');
        }
    });

    return (
        <div className="login-container">
            <div className="background-logo">
                <img src={LogoOutlineGlow} alt="Background Logo" />
            </div>
            <div className="login-content">
                <Link to="/" className="login-logo">
                    <img src={Logo} alt="Logo" />
                </Link>
                <div className="login-card">
                    <h1>Login</h1>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button onClick={() => googleLogin()} className="oauth-button google-button">
                        <FaGoogle className="oauth-icon-google" /> <span>Continue with Google</span>
                    </button>
                    <p className="or-text">Or</p>
                    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder=" "
                                {...register('email', { required: 'Email is required' })}
                                required
                            />
                            <label>Email Address</label>
                            {errors.email && <span className="error-message">{errors.email.message}</span>}
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder=" "
                                {...register('password', { required: 'Password is required' })}
                                required
                            />
                            <label>Password</label>
                            {errors.password && <span className="error-message">{errors.password.message}</span>}
                        </div>
                        <button type="submit" className="login-button">Login</button>
                    </form>
                    <p className="signup-link">
                        Don't have an account? <a href="/signup">Sign Up</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;