import React from 'react';
import './ImageModal.css';

const ImageModal = ({ src, alt, onClose }) => {
  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content">
        <img src={src} alt={alt} />
        <button className="image-modal-close" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default ImageModal;