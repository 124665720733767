import React, { useState } from 'react';

const LaunchingSoon = ({ onAdminLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdminLogin(username, password);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'var(--background-color)',
      color: 'var(--text-color)'
    }}>
      <h1>Our Site is Launching Soon</h1>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ marginRight: '10px', padding: '5px' }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginRight: '10px', padding: '5px' }}
        />
        <button type="submit" style={{
          padding: '5px 10px',
          backgroundColor: 'var(--primary-color)',
          color: 'var(--text-color)',
          border: 'none',
          cursor: 'pointer'
        }}>
          Admin Login
        </button>
      </form>
    </div>
  );
};

export default LaunchingSoon;