// src/docs/helpers/SearchContext.js
import React, { createContext, useState, useCallback } from 'react';

export const SearchContext = createContext({
  searchIndex: [],
  addPageContent: () => {}
});

export const SearchProvider = ({ children }) => {
  const [searchIndex, setSearchIndex] = useState([]);

  const addPageContent = useCallback((path, sections) => {
    setSearchIndex(prevIndex => {
      const newContent = sections.map(section => ({
        path,
        id: section.id,
        text: section.text,
        content: section.content,
      }));

      const updatedIndex = [...prevIndex];
      
      newContent.forEach(newEntry => {
        const existingEntryIndex = updatedIndex.findIndex(
          entry => entry.path === newEntry.path && entry.id === newEntry.id
        );
        
        if (existingEntryIndex === -1) {
          updatedIndex.push(newEntry);
        } else {
          updatedIndex[existingEntryIndex] = newEntry;
        }
      });

      if (JSON.stringify(prevIndex) === JSON.stringify(updatedIndex)) {
        return prevIndex;
      }

      return updatedIndex;
    });
  }, []);

  return (
    <SearchContext.Provider value={{ searchIndex, addPageContent }}>
      {children}
    </SearchContext.Provider>
  );
};