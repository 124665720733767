// src/components/ScrollIndicator.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './ScrollIndicator.css';

const ScrollIndicator = () => {
  const scrollIndicatorRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);
  const location = useLocation();
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  const checkScrollable = () => {
    const isPageScrollable = document.documentElement.scrollHeight > document.documentElement.clientHeight;
    setIsScrollable(isPageScrollable);
    if (!isPageScrollable && scrollIndicatorRef.current) {
      scrollIndicatorRef.current.style.height = '0%';
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    const documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (position / documentHeight) * 100;

    if (scrollIndicatorRef.current) {
      scrollIndicatorRef.current.style.height = `${scrolled}%`;
    }
  };

  const handleClick = (e) => {
    if (e.target.classList.contains('scroll-indicator-handle')) return;
    const { top, height } = scrollBarRef.current.getBoundingClientRect();
    const clickPosition = (e.clientY - top) / height;
    const scrollTarget = clickPosition * (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    window.scrollTo({ top: scrollTarget, behavior: 'smooth' });
  };

  const handleMouseDown = (e) => {
    if (!e.target.classList.contains('scroll-indicator-handle')) return;
    setIsDragging(true);
    setStartY(e.clientY);
    setStartScrollTop(window.pageYOffset);
    document.body.style.userSelect = 'none'; // Prevent text selection
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.style.userSelect = ''; // Re-enable text selection
  };

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      e.preventDefault();
      const deltaY = e.clientY - startY;
      const deltaPercentage = deltaY / window.innerHeight;
      const scrollAmount = deltaPercentage * (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      const newScrollTop = Math.max(0, Math.min(startScrollTop + scrollAmount, document.documentElement.scrollHeight - document.documentElement.clientHeight));
      window.scrollTo(0, newScrollTop);
    }
  }, [isDragging, startY, startScrollTop]);

  const checkDeviceType = useCallback(() => {
    const isMobileOrTablet = window.innerWidth <= 1024; // Adjust this breakpoint as needed
    setIsMobileOrTablet(isMobileOrTablet);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', checkScrollable);
    
    // Check if scrollable on mount and route change
    checkScrollable();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', checkScrollable);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', checkDeviceType);
    };
  }, [location, handleMouseMove, checkDeviceType]);

  if (!isScrollable || isMobileOrTablet) {
    return null;
  }

  return (
    <div 
      ref={scrollBarRef}
      className="scroll-indicator"
      onClick={handleClick}
    >
      <div 
        ref={scrollIndicatorRef} 
        className="scroll-indicator-bar"
      >
        <div 
          className="scroll-indicator-handle"
          onMouseDown={handleMouseDown}
        ></div>
      </div>
    </div>
  );
};

export default ScrollIndicator;