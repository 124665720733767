// src/components/Experts.js
import React, { useState, useEffect, useRef } from 'react';
import './Experts.css';
import { FaSpotify, FaYoutube, FaInstagram, FaFacebook, FaGlobe } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { getFilesPieChartData, filesPieChartOptions } from '../config/pieChartConfig';
import LoadingAnim from '../components/LoadingAnim';

import axios from 'axios';

const Experts = () => {
    const location = useLocation();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

    const [experts, setExperts] = useState([]);
    const [filteredExperts, setFilteredExperts] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedExpertise, setSelectedExpertise] = useState([]);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [expandedExpert, setExpandedExpert] = useState(null);
    const expertRefs = useRef({});
    const [dataForCategoryChart, setDataForCategoryChart] = useState(null);
    const [dataForItemChart, setDataForItemChart] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchExperts();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const selectedExpertId = searchParams.get('selected');
        if (selectedExpertId && experts.length > 0) {
            const expert = experts.find(e => e.id === parseInt(selectedExpertId));
            if (expert) {
                setExpandedExpert(expert);
                setTimeout(() => {
                    if (expertRefs.current[expert.id]) {
                        const yOffset = -150; // 80px header offset + 70 for margin
                        const element = expertRefs.current[expert.id];
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                    }
                }, 100);
            }
        }
    }, [location, experts]);

    useEffect(() => {
        if (expandedExpert && expandedExpert.person_categories_points) {
            const { dataForCategoryChart, dataForItemChart } = getFilesPieChartData(expandedExpert.person_categories_points);
            
            // Include person data in the datasets
            dataForCategoryChart.datasets[0].personData = expandedExpert.person_categories_points.map(() => ({
                name: expandedExpert.name,
                surname: expandedExpert.surname,
                id: expandedExpert.id,
            }));
            dataForItemChart.datasets[0].personData = expandedExpert.person_categories_points.flatMap(cat =>
                cat.items.map(() => ({
                    name: expandedExpert.name,
                    surname: expandedExpert.surname,
                    id: expandedExpert.id,
                }))
            );

            setDataForCategoryChart(dataForCategoryChart);
            setDataForItemChart(dataForItemChart);
        } else {
            setDataForCategoryChart(null);
            setDataForItemChart(null);
        }
    }, [expandedExpert]);

    const fetchExperts = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_BASE_URL}/get-experts`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            if (response.status === 200) {
                setExperts(response.data);
                setFilteredExperts(response.data);
            } else {
                console.error("Failed to fetch experts:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Error fetching experts:", error);
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);
        filterExperts(input, selectedExpertise);
    };

    const filterExperts = (search, expertise) => {
        const filtered = experts.filter(expert => {
            const nameMatch = `${expert.name} ${expert.surname}`.toLowerCase().includes(search);
            const expertiseMatch = expertise.length === 0 || expertise.some(item => expert.categories_of_expertise.includes(item));
            return nameMatch && expertiseMatch;
        });
        setFilteredExperts(filtered);
    };

    const toggleFilterModal = () => {
        setIsFilterModalOpen(!isFilterModalOpen);
    };

    const toggleExpertExpansion = (expert) => {
        setExpandedExpert(expandedExpert && expandedExpert.id === expert.id ? null : expert);
    };

    const getSocialIcon = (type) => {
        switch (type.toLowerCase()) {
            case 'twitter':
                return <FaXTwitter />;
            case 'spotify':
                return <FaSpotify />;
            case 'youtube':
                return <FaYoutube />;
            case 'instagram':
                return <FaInstagram />;
            case 'facebook':
                return <FaFacebook />;
            default:
                return <FaGlobe />;
        }
    };

    const renderExpert = (expert) => {
        const isExpanded = expandedExpert && expandedExpert.id === expert.id;
        const expertClass = isExpanded ? "expert-card expanded" : "expert-card";
    
        return (
            <div 
                key={expert.id} 
                className={expertClass} 
                onClick={() => toggleExpertExpansion(expert)}
                ref={el => expertRefs.current[expert.id] = el}
            >
                <div className="expert-card-content">
                    <img src={expert.image_url} alt={`${expert.name} ${expert.surname}`} className={`expert-image ${isExpanded ? 'expanded' : ''}`} />
                    <div className="expert-info">
                        <h3>{expert.name} {expert.surname}</h3>
                        {!isExpanded && <p>{expert.short_bio}</p>}
                    </div>
                </div>
                {isExpanded && (
                    <div className="expanded-content">
                        <div className="expanded-main-info">
                            <p className="long-bio">{expert.long_bio}</p>
                            <div className="expert-categories">
                                <h4>Expertise:</h4>
                                {expert.categories_of_expertise.map(category => (
                                    <span key={category} className="expert-category">{category}</span>
                                ))}
                            </div>
                        </div>
                        <div className="expanded-side-info">
                            {expert.social_links && Array.isArray(expert.social_links) && expert.social_links.length > 0 && (
                                <div className="expert-social-links">
                                    {expert.social_links.map((link, index) => (
                                        <a 
                                            key={index} 
                                            href={link.url} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            onClick={(e) => e.stopPropagation()}
                                            className="social-icon"
                                        >
                                            {getSocialIcon(link.type)}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="expert-charts">
                            <div className="expert-chart">
                                <h4>Expertise Distribution by Category:</h4>
                                {dataForCategoryChart && <Pie data={dataForCategoryChart} options={filesPieChartOptions} />}
                            </div>
                            <div className="expert-chart">
                                <h4>Expertise Distribution by Item:</h4>
                                {dataForItemChart && <Pie data={dataForItemChart} options={filesPieChartOptions} />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="experts-page">
            <h1 className="experts-header">Experts</h1>
            <p className="experts-subheader">
                This is a list of experts that you can choose from to create your very own personal AI Assistant. Assistants can be based on a single expert or combine multiple experts. We continue to add more experts to the list as well as increase data amounts - daily.
            </p>
            <p className="experts-subheader">
                Don't see the expert you follow and trust? Soon we will be adding a social hub where you can propose new experts and vote for existing proposals. We will run these votes every month and always add the most popular experts to our list.
            </p>
            <div className="experts-controls">
                <input
                    type="search"
                    placeholder="Search experts..."
                    value={searchInput}
                    onChange={handleSearchChange}
                    className="experts-search"
                />
                <button onClick={toggleFilterModal} className="filter-button">
                    Filter by Expertise
                </button>
            </div>
            {isFilterModalOpen && (
                <div className="filter-modal">
                    <h3>Select Expertise</h3>
                    {/* Add checkboxes for each expertise category */}
                    <button onClick={toggleFilterModal}>Close</button>
                </div>
            )}
            {isLoading ? (
                <LoadingAnim />
            ) : (
                <div className="experts-grid">
                    {filteredExperts.map(renderExpert)}
                </div>
            )}
        </div>
    );
};

export default Experts;