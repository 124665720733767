// src/components/Header.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaUser, FaCaretDown } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';

import Logo from '../assets/Logo_Colortext_whitetext.png';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { user, logout } = useContext(AuthContext);
    const location = useLocation();
    const dropdownRef = useRef(null);

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        logout();
        setIsDropdownOpen(false);
    };

    return (
        <header className="header">
            <div className="header-content">
                <Link to="/">
                    <img src={Logo} alt="Logo" className="logo" />
                </Link>
                <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
                    <div className="nav-content">
                        <ul className="nav-links">
                            {user ? (
                                <>
                                    <li><Link to="/dashboard" onClick={closeMenu}>Vitalise</Link></li>
                                    <li><Link to="/experts" onClick={closeMenu}>Experts</Link></li>
                                    <li><Link to="/news" onClick={closeMenu}>News</Link></li>
                                </>
                            ) : (
                                <>
                                    <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                                    <li><Link to="/experts" onClick={closeMenu}>Experts</Link></li>
                                    <li><Link to="/news" onClick={closeMenu}>News</Link></li>
                                    <li><Link to="/pricing" onClick={closeMenu}>Pricing</Link></li>
                                </>
                            )}
                            <li><a href="/docs" target="_blank" className="docs-link" onClick={closeMenu}>Docs <span className="arrow">↗</span></a></li>
                        </ul>
                        <div className="auth-buttons">
                            {user ? (
                                <div className="user-dropdown" ref={dropdownRef}>
                                    <button onClick={toggleDropdown} className="user-button">
                                        <FaUser className="user-icon" />
                                        {user.name}
                                        <FaCaretDown className="dropdown-icon" />
                                    </button>
                                    {isDropdownOpen && (
                                        <ul className="dropdown-menu">
                                            <li><Link to="/account">Account</Link></li>
                                            <li><Link to="/" onClick={handleLogout}>Log out</Link></li>
                                        </ul>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <Link to="/login" onClick={closeMenu}><button className="login-header-button">Log in</button></Link>
                                    <Link to="/signup" onClick={closeMenu}><button className="signup-header-button">Sign up</button></Link>
                                </>
                            )}
                        </div>
                    </div>
                </nav>
                <button className="menu-toggle" onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </button>
            </div>
            <div className="header-line"></div>
        </header>
    );
}

export default Header;