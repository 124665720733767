// src/docs/pages/GettingStarted.js
import React, { useState } from 'react';
import './Pages.css';
import ImageModal from '../helpers/ImageModal';

import ProfileGeneral from '../../assets/docs/profile1.jpg';
import ProfileGoals from '../../assets/docs/profile2.jpg';
import AssistantConfiguration from '../../assets/docs/assistant1.jpg';
import AssistantOverview from '../../assets/docs/assistant2.jpg';

const GettingStarted = () => {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (src, alt) => {
    setModalImage({ src, alt });
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div>
      <h1 id="Introduction">Getting Started</h1>
      <p>Welcome to the Getting Started guide for VitaliseAI. This guide will help you set up your profile and your first AI assistant, enabling you to make the most of the transformative features offered by VitaliseAI. Let's begin your journey to personalized health management.</p>

      <h2 id="Setting-Up-Your-Profile">Setting Up Your Profile</h2>
      <p>To provide you with the most accurate and personalized health advice, VitaliseAI requires some basic information about you. Setting up your profile includes entering details such as your age, height, weight, health goals, and ambitions. This information is crucial for tailoring the AI's recommendations to your specific needs.</p>

      <div className="getting-started-row">
        <div className="getting-started-text">
          <h3 id="Profile-Details">1. Profile Details</h3>
          <p>Navigate to the profile section and fill in your personal details. This includes:</p>
          <ul>
            <li>Age</li>
            <li>Height</li>
            <li>Weight</li>
            <li>Health Goals (e.g., weight loss, muscle gain, improved endurance)</li>
            <li>Ambitions (e.g., running a marathon, improving mental health, dietary changes)</li>
          </ul>
        </div>
        <img src={ProfileGeneral} alt="Profile General" className="page-image" onClick={() => openModal(ProfileGeneral, "Profile General")} />
      </div>

      <div className="getting-started-row">
        <div className="getting-started-text">
          <h3 id="Goals-Ambitions">2. Goals and Ambitions</h3>
          <p>Set your goals and ambitions to tailor the AI assistant's advice to your needs. This can include fitness goals, dietary restrictions, and other health-related objectives.</p>
        </div>
        <img src={ProfileGoals} alt="Profile Goals" className="page-image" onClick={() => openModal(ProfileGoals, "Profile Goals")} />
      </div>

      <h2 id="Setting-Up-Your-AI-Assistant">Setting Up Your AI Assistant</h2>
      <p>After setting up your profile, the next step is to configure your AI assistant. VitaliseAI allows you to customize your assistant by selecting from a diverse collection of health experts with various specialties. This customization ensures that the advice you receive is aligned with your health philosophy and goals.</p>

      <div className="getting-started-row">
        <div className="getting-started-text">
          <h3 id="Selecting-Experts">1. Selecting Experts</h3>
          <p>Choose from a wide range of experts in fields such as nutrition, fitness, mental health, and more. Each expert adds a unique perspective to your digital assistant's knowledge base, enhancing the quality of advice you receive.</p>
        </div>
        <img src={AssistantOverview} alt="Assistant Overview" className="page-image" onClick={() => openModal(AssistantOverview, "Assistant Overview")} />
      </div>

      <div className="getting-started-row">
        <div className="getting-started-text">
          <h3 id="Customizing-Expertise">2. Customizing Expertise</h3>
          <p>You can fine-tune the expertise of your assistant by selecting specific areas of focus. For example, if you are interested in improving your diet, you can prioritize nutrition experts. Similarly, if your goal is to enhance physical fitness, you can select fitness trainers and coaches.</p>
        </div>
        <img src={AssistantConfiguration} alt="Assistant Configuration" className="page-image" onClick={() => openModal(AssistantConfiguration, "Assistant Configuration")} />
      </div>

      <p className="update-date">Last updated on July 9, 2024</p>

      {modalImage && (
        <ImageModal 
          src={modalImage.src} 
          alt={modalImage.alt} 
          onClose={closeModal} 
        />
      )}
    </div>
  );
};

export default GettingStarted;